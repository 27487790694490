import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../utils/SnackerBar';
import { Global, inlineStyles } from '../../utils/global';
import { EnhancedTableToolbar } from '../../utils/common_page_with_table';
import { Box, MenuItem, Grid } from '@material-ui/core';

import 'date-fns';
import '../../styles.css';
import { numberFormat } from '../../utils/common_utils';
import 'bootstrap/dist/css/bootstrap.min.css';

const EntityName = 'Loan Extension';
const url = 'loans/extendLoan.php';

export default class ExtendLoan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.parentComponent.state.loanid,
            notification: '',
            isLoading: true,
            isDataLoading: false,
            objName: '',
            schema: [],
            rows: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            url: props.parentComponent.state.API_HOST + url,
            redirectTo: 'clients.loandetails',
            idFor: 'loanid',
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
        return;
    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }

    actionButtons = () => {
        return (
            <Box style={inlineStyles.toolbarActionBox}>
                <Button onClick={Global.handleSaveForm(this)} style={inlineStyles.button} color="primary" variant='contained' size="small">
                    <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Submit Loan Application
                </Button>

                <Button onClick={this.handleCancel} style={inlineStyles.buttonOrange} color="secondary" variant='contained' size="small">
                    <Icon className={"fas fa-arrow-circle-left"} style={inlineStyles.smallIcon}></Icon>Back to Loan Details
                </Button>
            </Box>
        )
    }
    handleCancel = () => {
        this.props.parentComponent.selectMenu('clients.loandetails', 0);
    }
    
    loadSchema = () => {
        if (this.state.isLoading && !this.state.isDataLoading) {
            var postData = [];
            postData.push({name: 'loanid', value: this.props.loanid});
            postData.push({name: 'clientID', value: this.props.App.state.clientID});
            Global.getSchema(this, true, this.pageIsLoaded, postData); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */
        }
    }
    componentDidUpdate() {
        this.loadSchema();
    }
    componentDidMount() {
        this.loadSchema();
    }
    pageIsLoaded = () => {
        this.setState({ isLoading: false });
    }
    reloadPage = () => {
        this.setState({ isLoading: true });
    }
    render() {
        const reportTitle = this.state.id > 0 ? "Edit " + EntityName : 'Add New ' + EntityName;
        var values = this.state.schema;
        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    <Button onClick={() => this.reloadPage()}>Reload page</Button>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={0}
                                toolbarTitle={reportTitle}
                                actionButtons={this.actionButtons}
                            />
                            <form autoComplete="off" style={{ padding: 15, borderBottom: '1px solid #cccccc' }}>
                                <Grid container spacing={2}>
                                    {values.map((row, index) => {
                                        if (row.name === 'monthly') {
                                            row.label += numberFormat(this.state.rows.MonthlyInstallment, 0) + ')';
                                        } else if (row.name === 'loanstatus') {
                                            //row.value='E';
                                        }
                                        return (
                                            < Grid item xs={12} md={row.gridsize ? row.gridsize : 6} key={index} >
                                                {row.type === 'dropdown' ?
                                                    <TextField
                                                        select
                                                        error={row.error ? true : false}
                                                        variant="outlined"
                                                        value={row.value}
                                                        label={row.label}
                                                        onChange={this.handleTextfieldChange(row.name)}
                                                        required={row.required}
                                                        name={row.name}
                                                        disabled={row.isDisabled}
                                                    >
                                                        {row.selection.length > 0 && row.selection.map(option => (
                                                            <MenuItem key={option.key} value={option.key}>
                                                                {option.value}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>                                                    
                                                    :
                                                    (
                                                        row.type === 'readonly'
                                                            ?
                                                            (
                                                                row.name === 'terms'
                                                                    ?
                                                                    <div className="input-group mb-3">
                                                                        <div class="input-group-prepend">
                                                                            <span className="input-group-text">Applied Accumulated Terms :</span>
                                                                        </div>
                                                                        <input type="text" className="form-control" value={row.value} readOnly />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">Months</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="input-group mb-3">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">{row.label} :</span>
                                                                        </div>
                                                                        <input type="text" name={row.name} className="form-control" value={row.value} readOnly />
                                                                    </div>
                                                            )
                                                            :

                                                            <TextField
                                                                error={row.error ? true : false}
                                                                variant="outlined"
                                                                margin="dense"
                                                                name={row.name}
                                                                required={row.required}
                                                                label={row.error ? row.error : row.label}
                                                                value={row.value}
                                                                onChange={this.handleTextfieldChange(row.name, index)}
                                                                disabled={this.state.isDialogDataLoading}
                                                            />

                                                    )
                                                }
                                            </Grid>

                                        )
                                    })
                                    }

                                </Grid>
                            </form>

                        </Fragment>
                    }

                </Paper>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}