import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../utils/SnackerBar';
import { Global, inlineStyles } from '../../utils/global';
import { EnhancedTableToolbar } from '../../utils/common_page_with_table';
import { Box, MenuItem, Grid } from '@material-ui/core';

import 'date-fns';
import '../../styles.css';
import { numberFormat } from '../../utils/common_utils';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextAutoSearch from '../clients/TextAutoSearch';

const url = 'loans/newloan.php';

export default class NewLoan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.parentComponent.state.loanid,
            branchId: null,
            notification: '',
            isLoading: true,
            searchUrl: url,
            isDataLoading: false,
            noList: true,
            objName: '',
            schema: [],
            rows: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            url: props.parentComponent.state.API_HOST + url,
            redirectTo: 'clients.loandetails',
            idFor: 'loanid',
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
        return;
    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }

    actionButtons = () => {
        return (
            <Box style={inlineStyles.toolbarActionBox}>
                <Button onClick={Global.handleSaveForm(this)} style={inlineStyles.button} color="primary" variant='contained' size="small">
                    <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Submit Loan Application
                </Button>
            </Box>
        )
    }
    handleCancel = () => {
        this.props.parentComponent.selectMenu('clients.loandetails', 0);
    }

    loadSchema = () => {
        if (this.state.isLoading && !this.state.isDataLoading) {
            var postData = [];
            postData.push({name: 'clientID', value: this.props.parentComponent.state.clientID});
            Global.getSchema(this, false, null, postData); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */
        }
    }
    componentDidUpdate() {
        if (this.state.branchId !== this.props.parentComponent.state.branchId) {
            this.setState({ branchId: this.props.parentComponent.state.branchId });
        }
        if (this.props.parentComponent.state.branchId === '0' && this.state.notification === '') {
            this.setState({ notification: 'Please select a branch' });
        } else if (this.props.parentComponent.state.branchId !== '0' && this.state.notification === 'Please select a branch') {
            /* clear out notification if branch has been selected and notification is not empty */
            this.setState({ notification: '' });
        }
        this.loadSchema();
    }
    componentDidMount() {
        if (this.state.branchId !== this.props.parentComponent.state.branchId) {
            this.setState({ branchId: this.props.parentComponent.state.branchId });
        }
        if (this.props.parentComponent.state.branchId === '0') {
            this.setState({ notification: 'Please select a branch' });
        }        
        this.loadSchema();    
    }
    
    pageIsLoaded = () => {
        this.setState({ isLoading: false });
    }
    updateRowObj = (tabname, suggestion) => {
        this.setState({ [tabname]: [suggestion.id, suggestion.label] });        
        Global.setStateValue(this, tabname, suggestion.id,  suggestion.label);
    }
    render() {
        const reportTitle = this.state.id > 0 ? "Edit Loan" : 'Add New Loan';
        var values = this.state.schema;
        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={0}
                                toolbarTitle={reportTitle}
                                actionButtons={this.state.notification === 'Please select a branch' ? '' : this.actionButtons}
                            />
                            <form autoComplete="off" style={{ padding: 15, borderBottom: '1px solid #cccccc' }}>
                                <Grid container spacing={2}>
                                    {values.map((row, index) => {
                                        if (row.name === 'monthly') {
                                            row.label += numberFormat(this.state.rows.MonthlyInstallment, 0) + ')';
                                        } else if (row.name === 'loanstatus') {
                                            //row.value='E';
                                        }
                                        return (
                                            < Grid className="loanform" item xs={12} md={row.gridsize ? row.gridsize : 6} key={index} >
                                                {
                                                    row.type === 'searchbox' ?
                                                        <Fragment>
                                                            {row.error ?
                                                            <div style={{ padding: 11, color: 'red' }}>{row.error}</div>
                                                            : ''}
                                                            <TextAutoSearch
                                                                parentComponent={this.props.parentComponent}
                                                                searchUrl={this.state.searchUrl}
                                                                updateRowObj={this.updateRowObj}
                                                                textValue={row.textvalue}
                                                                tabname={row.name} t={this} />

                                                        </Fragment>
                                                        :
                                                        row.type === 'dropdown' ?
                                                            <TextField
                                                                select
                                                                error={row.error ? true : false}
                                                                label={row.error ? row.error : row.label}
                                                                variant="outlined"
                                                                value={row.value}
                                                                onChange={this.handleTextfieldChange(row.name)}
                                                                required={row.required}
                                                                name={row.name}
                                                                disabled={row.isDisabled}
                                                            >
                                                                {row.selection.length > 0 && row.selection.map(option => (
                                                                    <MenuItem key={option.key} value={option.key}>
                                                                        {option.value}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                            :
                                                            (
                                                                row.type === 'readonly'
                                                                    ? <div className="input-group mb-3">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">{row.label} :</span>
                                                                        </div>
                                                                        <input type="text" name={row.name} className="form-control" value={row.value} readOnly />
                                                                    </div>
                                                                    :
                                                                    <TextField
                                                                        error={row.error ? true : false}
                                                                        label={row.error ? row.error : row.label}
                                                                        variant="outlined"
                                                                        margin="dense"
                                                                        name={row.name}
                                                                        required={row.required}
                                                                        value={row.value}
                                                                        onChange={this.handleTextfieldChange(row.name, index)}
                                                                        disabled={this.state.isDialogDataLoading}
                                                                    />
                                                            )
                                                }
                                            </Grid>

                                        )
                                    })
                                    }

                                </Grid>
                            </form>

                        </Fragment>
                    }

                </Paper>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}