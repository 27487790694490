import React from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import {  TextField } from '@material-ui/core';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginFileMetadata);
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


export default class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [

            ],
            caption: '',
        };
    }

    handleInit() {
        // console.log("FilePond instance has initialised", this.pond);
    }
    handleTextfieldChange = (v) => {
        this.setState({caption: v});
    }

    render() {
        var user = JSON.parse(sessionStorage.getItem('userdata'));
        if (typeof (user.token) === 'undefined' && !user.token) return;
        var colId = this.props.parentComponent.state.rows.pensionID;
        return (
            <div>
                <FilePond
                    ref={ref => (this.pond = ref)}
                    files={this.state.files}
                    allowMultiple={true}
                    maxFiles={5}
                    allowFileSizeValidation={true}
                    maxFileSize="100KB"
                    allowFileTypeValidation={true}
                    acceptedFileTypes={['image/*']}
                    server={this.props.App.state.API_HOST + "common/uploadImage.php"}
                    oninit={() => this.handleInit()}
                    fileMetadataObject= {{
                        colId: colId, 
                        token: user.token, 
                        user_id: user.userdata.id,
                        caption: this.state.caption
                    }}
                    onupdatefiles={fileItems => {                        
                        // Set currently active file objects to this.state
                        this.setState({
                            files: fileItems.map(fileItem => fileItem.file)
                        });
                    }}
                />
                <div>
                    <TextField
                        variant="outlined"
                        name="Caption"
                        label="Caption"
                        value={this.state.caption}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e)=>this.handleTextfieldChange(e.target.value)}
                        disabled={this.state.isDataLoading}
                    />
                </div>
                <div style={{ fontSize: 10, paddingTop: 50 }}>
                    NOTE:
        <ul>
                        <li>Upload pictures with maximum of 100kb filesize.</li>
                        <li>500px width and height.</li>
                        <li>Upload upto five (5) pictures only.</li>
                    </ul>

                </div>
            </div>
        );
    }
}