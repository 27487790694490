import React, { Fragment, Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { numberFormat } from '../../utils/common_utils';
import 'date-fns';
import '../../styles.css';
import { EnhancedTableToolbar, EnhancedTableHead } from '../../utils/common_page_with_table';
import { Global, inlineStyles } from '../../utils/global';
import SnackerBar from '../../utils/SnackerBar';
import Button from '@material-ui/core/Button';
import { Link } from '@material-ui/core';
import { Column, Row } from 'simple-flexbox';
import 'date-fns';

const reportTitle = "CMC Disbursements Report";
const url = 'cash/disbursements.php';

export default class Disbursements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 1,
            deleteDialogOpen: false,
            editDialogOpen: false,
            dense: false,
            id: 0,
            isLoading: true,
            isDataLoading: false,
            isDialogDataLoading: false,
            notification: '',
            objName: '',
            order: 'desc',
            orderBy: 'dateTransaction',
            page: 0,
            pageTotal: 0,
            rows: [],
            rowsPerPage: 100,
            searchFor: '',
            selected: [],
            selectedDate: '',
            selectedDate2: '',
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            showClearSearchBtn: false,
            url: props.parentComponent.state.API_HOST + url,
            schema: [],
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    handleRequestSort = (event, property) => {
        var isDesc = this.state.order === 'desc';
        var order = isDesc || this.state.orderBy !== property ? 'asc' : 'desc';
        var values = {
            order: order,
            orderBy: property,
        };
        this.setComponentState('order', order);
        this.setComponentState('orderBy', property);
        Global.loadList(this, values);
    }
    changeDate = (e) => {
        var v = e.target.value;
        this.setState({ selectedDate: v });
    }
    changeDate2 = (e) => {
        var v = e.target.value;
        this.setState({ selectedDate2: v });
    }
    setSearch = () => {
        if (this.state.selectedDate) {
            Global.getDataRows(this, this.state.url, 'rows', '', [{ name: 'selectedDate', value: this.state.selectedDate }, { name: 'selectedDate2', value: this.state.selectedDate2 }]);
        }
    }
    previewCMC = (transID) => {
        Global.showPostPage(this, this.state.url, 'printByBranch', [{ name: 'reportdate', value: this.state.selectedDate },{ name: 'transID', value: transID}]);
    }
    printCMC = () => {
        Global.showPostPage(this, this.state.url, 'printAll', [{ name: 'reportdate', value: this.state.selectedDate }]);
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            var newSelecteds = this.state.rows.map(n => n.name);
            this.setComponentState('selected', newSelecteds);
            return;
        }
        this.setComponentState('selected', []);
    }

    handleChangePage = (event, newPage) => {
        this.setComponentState('page', newPage);
        Global.loadList(this, { page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setComponentState('rowsPerPage', event.target.value);
        Global.loadList(this, { rowsPerPage: event.target.value });
    }

    handleChangeDense = (event) => {
        this.setComponentState('dense', event.target.checked);
    }


    actionButtons = () => {
        return (
            <Column flexGrow={1}>
                <Row vertical='center' horizontal='right'>
                <Column flexGrow={1} horizontal='center'>
                    From: 
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        <TextField
                            error={false}
                            variant="outlined"
                            margin="dense"
                            type="date"
                            required={true}
                            onChange={this.changeDate}
                            disabled={this.state.isDataLoading}
                        />
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                    To: 
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        <TextField
                            error={false}
                            variant="outlined"
                            margin="dense"
                            type="date"
                            required={true}
                            onChange={this.changeDate2}
                            width={100}
                            disabled={this.state.isDataLoading}
                        />
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        <Button onClick={this.setSearch}  style={{ marginLeft: 5, padding: "0 5px" }} color="primary" variant='contained' size="small">
                            Search
                            </Button>
                    </Column>
                    
                </Row>
            </Column>
        )
    }

    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, false);
        }
    }
    render() {
        var headRows = [];
        var formValues = this.state.schema;
        formValues.forEach((row) => {
            headRows.push({ id: row.name, label: row.label, numeric: row.numeric, showInList: row.showInList });
        });

        headRows.push({ id: 'action', label: 'Actions', numeric: false, showInList: true });

        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={this.state.selected ? this.state.selected.length : 0}
                                toolbarTitle={reportTitle}
                                actionButtons={this.actionButtons}
                                t={this}
                                Global={Global}
                            />
                            <div style={inlineStyles.tableWrapper}>
                                <Table
                                    style={inlineStyles.table}
                                    aria-labelledby="tableTitle"
                                    size={this.state.dense ? 'medium' : 'small'}
                                >
                                    <EnhancedTableHead
                                        numSelected={this.state.selected ? this.state.selected.length : 0}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        headRows={headRows}
                                    />
                                    <TableBody>
                                        {this.state.rows && this.state.rows.length > 0 && this.state.rows.map((row, index) => {
                                            const labelId = `table-row-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                >
                                                    <TableCell className="listRowNum" id={labelId} scope="row" padding="none" align="center">
                                                        {index + 1 + (this.state.page * this.state.rowsPerPage)}
                                                    </TableCell>
                                                    {formValues.filter(col => col.showInList).map((col, colindex) => {
                                                        const colval = colindex
                                                            ? row[col.name]
                                                            : (<Link to="#" onClick={() => this.previewCMC(row.transID)} >{row[col.name]}</Link>);
                                                        return (
                                                            <TableCell key={colindex} align={col.numeric ? 'right' : (col.align ? col.align : 'left')}>
                                                                {col.numeric ? numberFormat(colval,2) : colval}
                                                            </TableCell>
                                                        );
                                                    })
                                                    }

                                                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                                        <Button color="primary" variant="outlined" size="small"  onClick={() => this.previewCMC(row.transID)}>
                                                            View
                                                        </Button>                                                        
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        }
                                        {this.state.rows && !this.state.rows.length &&
                                            <TableRow><TableCell align="center" colSpan={8}>{"No result"}</TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            {!this.state.isDataLoading && this.state.pageTotal > 1 &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={this.state.count}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            }
                        </Fragment>
                    }
                </Paper>
                <FormControlLabel
                    control={<Switch checked={this.state.dense} onChange={this.handleChangeDense} />}
                    label="Show bigger cells"
                />

                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}
