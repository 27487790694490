import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../utils/SnackerBar';
import { Global, inlineStyles } from '../../utils/global';
import { EnhancedTableToolbar } from '../../utils/common_page_with_table';
import { Box, Grid} from '@material-ui/core';

import 'date-fns';
import '../../styles.css';

const url = process.env.REACT_APP_EMPINFO_URL;

export default class EmploymentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,       
            notification: '',
            isLoading: true,
            isDataLoading: false,
            selectedID: 0,
            objName: '',
            schema: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            url: props.parentComponent.state.API_HOST+url,
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
        return;
    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }    

    actionButtons = () => {
        return (
            <Box style={inlineStyles.toolbarActionBox}>
                <Button onClick={Global.handleSaveForm(this)} style={inlineStyles.button} color="primary" variant='contained' size="small">
                    <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save Employer
                </Button>
            </Box>
        )
    }    

    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, true); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */
        }
    }
    render() {
        const reportTitle = 'Employer Information'
        var values = this.state.schema;
        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={0}
                                toolbarTitle={reportTitle}
                                actionButtons={this.actionButtons}
                            />
                            <form autoComplete="off" style={inlineStyles.editform}>
                                <Grid container spacing={2}>
                                    {values.filter((row) => row.type === 'text').map((row, index) => {
                                        return (
                                            <Grid item xs={6} md={row.gridSize ? row.gridSize : 4} key={index}>
                                                <TextField
                                                    error={row.error ? true : false}
                                                    variant="outlined"
                                                    margin="dense"
                                                    name={row.name}
                                                    required={row.required}
                                                    label={row.error ? row.error : row.label}
                                                    value={row.value}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={this.handleTextfieldChange(row.name)}
                                                    disabled={this.state.isDataLoading}
                                                />
                                            </Grid>
                                        )
                                    })
                                    }                                    
                                </Grid>
                            </form>
                        </Fragment>
                    }
                    
                </Paper>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}

