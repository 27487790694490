import React, { Component, Fragment } from 'react'
import { Grid, IconButton,Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { Global, inlineStyles } from '../../utils/global';
import DeleteIcon from '@material-ui/icons/Delete';

export class CollateralImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collateralImages: {},
            deleteDialogOpen: false,
            objName: '',
            selectedTable: 'collateral_image2',
            selectedId: 0,
            isDialogDataLoading: false,
            isLoading: true,
        }
    }
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    confirmDeleteRow(id, name) {
        var states = this.state;
        states.selectedId = id;
        states.objName = name;
        this.setState({ states });
        this.setDeleteDialogOpen(true);
    }
    confirmDeletion = () => {
        Global.deleteRowByTab(this, this.state.selectedId);
    }
    setDeleteDialogOpen = (v) => {
        this.setState({ deleteDialogOpen: v });
    }
    setListData = (tab,rows) => {
        this.setState({ collateralImages: rows});
    }
    componentDidUpdate() {
        if (this.state.isLoading || this.props.reloadPage) {
            this.setState({ isLoading: false });
            this.props.parentComponent.setState({ reloadPage: false });
            Global.getDataRows(this, this.props.App.state.API_HOST + process.env.REACT_APP_CLIENT_LOAN_DETAILS_URL, 'collateralImages', 'collateral_image', [{ name: 'loanid', value: this.props.parentComponent.state.id }]);    
        }
    }
    componentDidMount()  {
        Global.getDataRows(this, this.props.App.state.API_HOST + process.env.REACT_APP_CLIENT_LOAN_DETAILS_URL, 'collateralImages', 'collateral_image', [{ name: 'loanid', value: this.props.parentComponent.state.id }]);
        this.setState({ isLoading: false });
    }
    render() {
        return (
            <Fragment>                                
                <Grid container spacing={2}>
                    {this.state.collateralImages.length > 0 && this.state.collateralImages.map((row, index) => {                        
                        return (<Grid item xs={6} md={4} key={index} style={{ position: 'relative' }}>
                            {row.path === 'new'
                                ?
                                <img src={process.env.REACT_APP_DOMAIN + row.img_src} style={{ width: '100%' }} alt="new collateral" />
                                :
                                <img src={process.env.REACT_APP_IMAGE_DOMAIN + row.img_src} style={{ width: '100%' }} alt="collateral" />
                            }
                            <IconButton onClick={() => this.confirmDeleteRow(row.image_id,row.caption)} variant="contained" color="secondary" size="small" aria-label="Delete" style={{ position: 'absolute', top: 10, right: 10 }}>
                                <DeleteIcon />
                            </IconButton>
                            <center>{row.caption}</center>
                        </Grid>
                        )
                    })
                    }
                </Grid>
                <Dialog
                    open={this.state.deleteDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description"></DialogContentText>
                        <div style={{ padding: 15 }}>
                            Do you want to continue deleting "{this.state.objName}"?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setDeleteDialogOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            No
                    </Button>
                        <Button onClick={() => this.confirmDeletion(this.state.id)} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                            Yes
                    </Button>
                    </DialogActions>
                </Dialog>

            </Fragment>
        )
    }
}

export default CollateralImages
