import React, { Fragment, Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Welcome from './contents/Welcome'
import NotFound from './contents/NotFound';
import SignIn from './forms/Signin';
import Users from './contents/settings/Users';
import { loadCSS } from 'fg-loadcss';
import Edituser from './contents/settings/Edituser';
import { inlineStyles, Global } from './utils/global';
import Roles from './contents/settings/Roles';
import underConstruction from './underConstruction.svg';
import Clients from './contents/clients/Clients';
import EditClients from './contents/clients/EditClients';
import Branches from './contents/settings/Branches';
import Banks from './contents/settings/Banks';
import Products from './contents/settings/Products';
import LoanCharges from './contents/settings/LoanCharges';
import ClientDetails from './contents/clients/Details';
import { TextField, InputBase, Icon } from '@material-ui/core';
import Processing from './contents/loans/processing';
import Approval from './contents/loans/approval';
import Approved from './contents/loans/approved';
import Granted from './contents/loans/granted';
import LoanDetails from './contents/clients/LoanDetails';
import EditRole from './contents/settings/Editrole';
import MyProfile from './contents/settings/Profile';
import ExtendLoan from './contents/loans/ExtendLoan';
import NewLoan from './contents/loans/NewLoan';
import Consolidated from './contents/cmc/Consolidated';
import Transactions from './contents/cmc/Transactions';
import RecapOfDeposits from './contents/cmc/Recap';
import Disbursements from './contents/cmc/Disbursements';
import Adjustments from './contents/cmc/Adjustments';
import BranchBanks from './contents/cmc/BranchBanks';
import BankTransactions from './contents/cmc/bankTransactions';
import CmcSettings from './contents/cmc/CmcSettings';
import AccountGroups from './contents/generalLedger/AccountGroups';
import GLaccount from './contents/generalLedger/GLaccount';
import GeneralJournal from './contents/generalLedger/GeneralJournal';
import TransactionsJournal from './contents/generalLedger/TransactionsJournal';
import crJournal from './contents/accountingReports/crJournal';

require('dotenv').config();

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: { // after the dash
        position: 'relative',
      },
    },
    MuiIconButton: {
      colorInherit: {
        color: 'inherit',
      },
      colorSecondary: {
        color: '#fff',
        backgroundColor: 'rgb(220, 0, 78)',
        '&:hover': {
          backgroundColor: '#333',
        }
      },
    },
    MuiSelect: {
      root: {
        margin: 0,
        padding: 0,
        backgroundColor: '#fff',
      },
      selectMenu: {
        width: '90%',
        minHeight: 16,
        margin: 1,
        backgroundColor: '#fff !important',
      },
      icon: {
        position: 'absolute',
        top: 5,
        right: 5,
        width: 20,
        height: 20,
      },
    },
    MuiTab: {
      root: {
        padding: '3 12',
        '&:hover': {
          color: '#3c8dd4',
        }
      },
      labelIcon: {
        padding: 0,
        paddingTop: 0,
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '95% !important',
        fontFamily: 'Arial',
      },
      sizeSmall: {
        padding: 5,
      }
    },    
    MuiTablePagination: {
      input: {
        flexShrink: 1,
        border: '1px solid #d2e6d5',
        width: 98,
        padding: 0,
      },
      select: {
        paddingRight: 24,
        textAlignLast: 'left !important',
      },
      selectRoot: {
        marginRight: 16,
        width: 45,
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '95%',
      },
      body2: {
        fontSize: '90%',
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: 36,
      }
    },
    MuiTextField: {
      root: {
        width: '100%',
      }
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        color: '#1a6db7 !important',
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '100%',
      }
    },
    MuiDialogContentText: {
      root: {
        marginBottom: 10,
        fontSize: '90%',
      }
    },
    MuiInputBase: {
      root: {
        width: '100%',
        padding: '5px',
        fontSize: '95%',
      },
      input: {
        width: '100%',
      }
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: 5,
        paddingBottom: 5,
      },
      multiline: {
        backgroundColor: '#fff',
      }
    },
  },
  palette: {
    primary: {
      main: '#3c8dd4',
      light: '#ccc',
      dark: '#6ab4f5'
    },
    secondary: {
      main: '#d32f2f'
    },
    action: {
      main: '#6495ed',
      light: '#ccc',
      dark: '#637282',
    }
  },
});

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      API_HOST: 'https://cas.ycfcserver.com/' + process.env.REACT_APP_API,
      branchId: 0,
      branches: [],
      clientID: 0,
      leftDrawerOpen: true,
      snackBarMsg: { msg: '', type: '' },
      anchorEl: null,
      selectedMenu: '/',
      collapseMenu_loans: false,
      collapsed_cmc: false,
      collapsed_gl: false,
      collapsed_trans: false,
      collapsed_loanreports: false,
      collapsed_actgrep: false,
      collapsed_settings: false,
      updateThePage: true,
      isDataLoading: false,
      latestClients: [],
      loadedCount: 0, /* for chart data in dashboard */
      userdata: {},
      loanid: 0,
      loanProcessingCount: { rows: '...' },
      loanForApprovalCount: { rows: '...' },
      loanApprovedCount: { rows: '...' },
      loanGrantedCount: { rows: '...' },
      monthlyCollections: { loaded: false, rows: [], count: 0 },
      monthlyDisbursements: { loaded: false, rows: [], count: 0 },
      monthlyAdjustments: { loaded: false, rows: [], count: 0 },
      monthlyRecap: { loaded: false, rows: [], count: 0 },
      menuId: 'primary-search-account-menu',
      notification: '',
      permissions: {
        DashboardGraphs: '', Clients: '',
        Loans: '', LoanProcessing: '', LoanApproval: '', LoanApproved: '', LoanGranted: '',
        CashMovement: '', CMCConsolidated: '', CMCTransactions: '', CMCDisbursements: '', CMCAdjustments: '', CMCRecapDeposits: '', CMCBanks: '', CMCBranchesBanks: '', CMCSettings: '', CMCBankTransactions: '', CMCTransactionReports: '',
        GLjournal:'', GLaccount:'', GLacctgroup:'', GLchartofaccount:'', GLTransactionJournal:'',
        GeneralLedger: '', TransactionsMenu: '', LoanReports: '', AccountingReports: '', SAP: '', Settings: ''
      },
      searchTable: 'Client',
      searchTableVal: '',
      searchResult: [],
      searchStarted: false,
      selectedID: 0,
      selectedLabel: '',
    }
  }
  collapseMenu_loans = (v) => {
    this.setState({ collapsed_loans: v });
  }
  collapseMenu_cmc = (v) => {
    this.setState({ collapsed_cmc: v });
  }
  collapseMenu_gl = (v) => {
    this.setState({ collapsed_gl: v });
  }
  collapseMenu_trans = (v) => {
    this.setState({ collapsed_trans: v });
  }
  collapseMenu_loanreports = (v) => {
    this.setState({ collapsed_loanreports: v });
  }
  collapseMenu_actgrep = (v) => {
    this.setState({ collapsed_actgrep: v });
  }
  collapseMenu_settings = (v) => {
    this.setState({ collapsed_settings: v });
  }
  checkPermission = (featureId) => {
    var userdata = JSON.parse(sessionStorage.getItem('userdata'));
    if (userdata.hasOwnProperty('permissions') && userdata.permissions.hasOwnProperty(featureId)) {
      return userdata.permissions[featureId];
    } else {
      return false;
    }
  }
  componentDidUpdate() {
    if (this.state.updateThePage && !this.state.searchTableVal && (!this.state.selectedMenu || this.state.selectedMenu === '/')) {
      this.setState({ updateThePage: false });
      var permissions = this.state.permissions;

      if ((permissions.hasOwnProperty('LoanProcessing') && permissions.LoanProcessing.indexOf('f') !== -1) || permissions.LoanProcessing.indexOf('v') !== -1) {
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_LOANS_URL, 'loanProcessingCount', 'getProcessingCount');
      }
      if ((permissions.hasOwnProperty('LoanApproval') && permissions.LoanApproval.indexOf('f') !== -1) || permissions.LoanApproval.indexOf('v') !== -1) {
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_LOANS_URL, 'loanForApprovalCount', 'getForApprovalCount');
      }
      if ((permissions.hasOwnProperty('LoanApproved') && permissions.LoanApproved.indexOf('f') !== -1) || permissions.LoanApproved.indexOf('v') !== -1) {
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_LOANS_URL, 'loanApprovedCount', 'getApprovedCount');
      }
      if ((permissions.hasOwnProperty('LoanGranted') && permissions.LoanGranted.indexOf('f') !== -1) || permissions.LoanGranted.indexOf('v') !== -1) {
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_LOANS_URL, 'loanGrantedCount', 'getGrantedCount');
      }

      if ((permissions.hasOwnProperty('DashboardGraphs') && permissions.DashboardGraphs.indexOf('f') !== -1) || permissions.DashboardGraphs.indexOf('v') !== -1) {
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_TRANSACTIONS_URL, 'monthlyCollections', 'getMonthlyCollections');
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_TRANSACTIONS_URL, 'monthlyDisbursements', 'getMonthlyDisbursements');
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_TRANSACTIONS_URL, 'monthlyAdjustments', 'getMonthlyAdjustments');
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_TRANSACTIONS_URL, 'monthlyRecap', 'getMonthlyRecap');
      }
      if ((permissions.hasOwnProperty('Clients') && permissions.Clients.indexOf('f') !== -1) || permissions.Clients.indexOf('v') !== -1) {
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_CLIENTS_URL, 'latestClients', 'latest');
      }
      if ((permissions.hasOwnProperty('Settings') && permissions.Settings.indexOf('f') !== -1) || permissions.Settings.indexOf('v') !== -1) {
        Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_USERS_URL, 'activeUsers', 'latest');
      }
      //Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_BRANCHES_URL, 'branches');

    }
  }
  componentDidMount() {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    if (sessionStorage.getItem('userdata')) {
      var vUserdata = JSON.parse(sessionStorage.getItem('userdata'));
      var vBranchId = vUserdata.userdata.branchId;
      var oStates = this.state;
      oStates.userdata = vUserdata;
      oStates.branchId = vBranchId;

      var permissions = this.state.permissions;

      var checkedPermissions;

      for (var featureId in permissions) {
        checkedPermissions = this.checkPermission(featureId);
        if (checkedPermissions) {
          permissions[featureId] = checkedPermissions;
        }
      }
      oStates.branches = vUserdata.branches;
      oStates.permissions = permissions;
      this.setState({ oStates });
    }
  }

  selectMenu = (sMenuitem, Id, oMsg, oStates) => {
    var states = this.state;
    if (typeof Id === 'object' && Id !== null) {
      if (Id.hasOwnProperty('idFor')) {
        states[Id.idFor] = Id.value;
      }
    } else if (typeof Id === 'string' || typeof Id === 'number') {
      states.selectedID = Id;
    }

    if (typeof oMsg !== 'undefined' && oMsg !== null) {
      states.snackBarMsg = oMsg;
    } else {
      states.snackBarMsg = { msg: '', type: '' };
    }
    states.selectedMenu = sMenuitem;
    if (typeof oStates === 'object') {
      oStates.forEach((row) => {
        states[row.name] = row.value;
      })
    }
    this.setState({ states });
  }

  handleCollapseMenu = (menuId) => {
    switch (menuId) {
      case 'Loans':
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);

        this.collapseMenu_loans(!this.state.collapsed_loans);
        break;
      case 'CashMovement':
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);

        this.collapseMenu_cmc(!this.state.collapsed_cmc);
        break;
      case 'GeneralLedger':
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);

        this.collapseMenu_gl(!this.state.collapsed_gl);
        break;
      case 'TransactionsMenu':
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);

        this.collapseMenu_trans(!this.state.collapsed_trans);
        break;
      case 'LoanReports':
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);

        this.collapseMenu_loanreports(!this.state.collapsed_loanreports);
        break;
      case 'AccountingReports':
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);

        this.collapseMenu_actgrep(!this.state.collapsed_actgrep);
        break;
      case 'Settings':
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);

        this.collapseMenu_settings(!this.state.collapsed_settings);
        break;
      default:
        if (this.state.collapsed_loans) this.collapseMenu_loans(false);
        if (this.state.collapsed_cmc) this.collapseMenu_cmc(false);
        if (this.state.collapsed_trans) this.collapseMenu_trans(false);
        if (this.state.collapsed_gl) this.collapseMenu_gl(false);
        if (this.state.collapsed_loanreports) this.collapseMenu_loanreports(false);
        if (this.state.collapsed_actgrep) this.collapseMenu_actgrep(false);
        if (this.state.collapsed_settings) this.collapseMenu_settings(false);
    }
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleLeftDrawer = () => {
    this.setState({ leftDrawerOpen: !this.state.leftDrawerOpen });
  }

  logout = () => {
    this.handleMenuClose();
    var oStates = this.state;
    oStates.userdata = {};
    oStates.anchorEl = null;
    this.setState({ oStates });
    sessionStorage.clear();
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  }
  setSearchTable = (v) => {
    this.setState({ searchTable: v });

  }
  clearSearch = () => {
    var oStates = this.state;
    oStates.searchTableVal = '';
    oStates.searchStarted = false;
    this.setState({ oStates });
  }
  setSearchVal = (v) => {
    this.setState({ searchTableVal: v });
  }
  startSearch = () => {
    var oStates = this.state;
    oStates.searchResult = '';
    oStates.selectedMenu = '';
    oStates.isDataLoading = true;
    oStates.searchStarted = true;
    this.setState({ oStates });
    if (this.state.searchTable === 'Client') {
      Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_CLIENTS_URL, 'searchResult', 'getAll');
    } else {
      Global.getDataRows(this, this.state.API_HOST + process.env.REACT_APP_CLIENT_LOANS_URL, 'searchResult', 'getAll');
    }
  }
  loadingCircular = () => {
    return (
      <Fragment>
        <div style={{ 'padding': 50, 'textAlign': 'center' }}>
          <CircularProgress />
        </div>
      </Fragment>
    )
  }
  incrementLoadedCount = () => {
    var oStates = this.state;
    oStates.loadedCount = oStates.loadedCount + 1;
    this.setState({ oStates });
  }
  setBranch = (v) => {
    var oStates = this.state;
    oStates.updateThePage = true;
    oStates.branchId = v;
    oStates.loadedCount = 0;
    this.setState({ oStates });
  }
  render() {
    var userdata = this.state.userdata;
    var content = '';
    var recId = this.state.clientID ? this.state.clientID : 0;
    var permissions = this.state.permissions;
    var menuItems = [];
    var subItems;

    menuItems.push({ name: 'Dashboard', menuid: 'welcome', icon: <i className="material-icons">dashboard</i> });
    if ((permissions.hasOwnProperty('Clients') && permissions.Clients.indexOf('f') !== -1) || permissions.Clients.indexOf('v') !== -1) {
      menuItems.push({ name: 'Clients', menuid: 'Clients', icon: <i className="material-icons">contacts</i> });
    }
    if ((permissions.hasOwnProperty('Loans') && permissions.Loans.indexOf('f') !== -1) || permissions.Loans.indexOf('v') !== -1) {
      subItems = [];
      if ((permissions.hasOwnProperty('Loans') && permissions.Loans.indexOf('f') !== -1) || permissions.Loans.indexOf('c') !== -1) {
        subItems.push({ name: 'New Loan', menuid: 'loans.new', inProgress: false });
      }
      if ((permissions.hasOwnProperty('LoanProcessing') && permissions.LoanProcessing.indexOf('f') !== -1) || permissions.LoanProcessing.indexOf('v') !== -1) {
        subItems.push({ name: 'Processing', menuid: 'loans.processing', inProgress: false });
      }
      if ((permissions.hasOwnProperty('LoanApproval') && permissions.LoanApproval.indexOf('f') !== -1) || permissions.LoanApproval.indexOf('v') !== -1) {
        subItems.push({ name: 'For Approval', menuid: 'loans.forApproval', inProgress: false });
      }
      if ((permissions.hasOwnProperty('LoanApproved') && permissions.LoanApproved.indexOf('f') !== -1) || permissions.LoanApproved.indexOf('v') !== -1) {
        subItems.push({ name: 'Approved', menuid: 'loans.approved', inProgress: false });
      }
      if ((permissions.hasOwnProperty('LoanGranted') && permissions.LoanGranted.indexOf('f') !== -1) || permissions.LoanGranted.indexOf('v') !== -1) {
        subItems.push({ name: 'Granted', menuid: 'loans.granted', inProgress: false });
      }

      menuItems.push({
        name: 'Loans', menuid: 'Loans', icon: <i className="material-icons">monetization_on</i>,
        subItems: subItems
      });
    }
    if ((permissions.hasOwnProperty('CashMovement') && permissions.CashMovement.indexOf('f') !== -1) || permissions.CashMovement.indexOf('v') !== -1) {
      subItems = [];
      if (permissions.hasOwnProperty('CMCConsolidated') && (permissions.CMCConsolidated.indexOf('f') !== -1 || permissions.CMCConsolidated.indexOf('v') !== -1)) {
        subItems.push({ name: 'Consolidated CMC', menuid: 'cmc.consolidated', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCTransactions') && (permissions.CMCTransactions.indexOf('f') !== -1 || permissions.CMCTransactions.indexOf('v') !== -1)) {
        subItems.push({ name: 'Transactions', menuid: 'cmc.transactions', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCDisbursements') && (permissions.CMCDisbursements.indexOf('f') !== -1 || permissions.CMCDisbursements.indexOf('v') !== -1)) {
        subItems.push({ name: 'Disbursements', menuid: 'cmc.disbursements', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCAdjustments') && (permissions.CMCAdjustments.indexOf('f') !== -1 || permissions.CMCAdjustments.indexOf('v') !== -1)) {
        subItems.push({ name: 'Adjustments', menuid: 'cmc.adjustments', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCRecapDeposits') && (permissions.CMCRecapDeposits.indexOf('f') !== -1 || permissions.CMCRecapDeposits.indexOf('v') !== -1)) {
        subItems.push({ name: 'Recap Deposits', menuid: 'cmc.recap', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCBanks') && (permissions.CMCBanks.indexOf('f') !== -1 || permissions.CMCBanks.indexOf('v') !== -1)) {
        subItems.push({ name: 'Banks', menuid: 'cmc.banks', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCBranchesBanks') && (permissions.CMCBranchesBanks.indexOf('f') !== -1 || permissions.CMCBranchesBanks.indexOf('v') !== -1)) {
        subItems.push({ name: 'Branches and Banks', menuid: 'cmc.branchesbanks', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCBankAccounts') && (permissions.CMCBankAccounts.indexOf('f') !== -1 || permissions.CMCBankAccounts.indexOf('v') !== -1)) {
        subItems.push({ name: 'Bank Accounts', menuid: 'cmc.bankaccounts', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCBankTransactions') && (permissions.CMCBankTransactions.indexOf('f') !== -1 || permissions.CMCBankTransactions.indexOf('v') !== -1)) {
        subItems.push({ name: 'Bank Transactions', menuid: 'cmc.banktransactions', inProgress: false });
      }

      if (permissions.hasOwnProperty('CMCTransactionReports') && (permissions.CMCTransactionReports.indexOf('f') !== -1 || permissions.CMCTransactionReports.indexOf('v') !== -1)) {
        subItems.push({ name: 'Transaction Reports', menuid: 'cmc.transactionreports', inProgress: false });
      }
      if (permissions.hasOwnProperty('CMCSettings') && (permissions.CMCSettings.indexOf('f') !== -1 || permissions.CMCSettings.indexOf('v') !== -1)) {
        subItems.push({ name: 'CMC Settings', menuid: 'cmc.settings', inProgress: false });
      }
      // subItems.push({ name: 'Cash Receipts (OR)', menuid: 'trans.or', inProgress: true });
      // subItems.push({ name: 'Sales Book (PN)', menuid: 'trans.salesbook', inProgress: true });
      menuItems.push({
        name: 'Cash Movement', menuid: 'CashMovement', icon: <i className="material-icons">local_atm</i>,
        subItems: subItems
      });
    }

    if ((permissions.hasOwnProperty('GeneralLedger') && permissions.GeneralLedger.indexOf('f') !== -1) || permissions.GeneralLedger.indexOf('v') !== -1) {
      subItems = [];
      subItems.push({ name: 'General Journal', menuid: 'gl.journal', inProgress: false });
      subItems.push({ name: 'GL Accounts', menuid: 'gl.account', inProgress: false });
      subItems.push({ name: 'Chart of Accounts', menuid: 'gl.acctchart', inProgress: false });
      subItems.push({ name: 'Transactions Journal', menuid: 'gl.tranjournal', inProgress: false });

      menuItems.push({
        name: 'General Ledger', menuid: 'GeneralLedger', icon: <i className="material-icons">subtitles</i>,
        subItems: subItems
      });
    }

    if ((permissions.hasOwnProperty('TransactionsMenu__') && permissions.TransactionsMenu.indexOf('f') !== -1) || permissions.TransactionsMenu.indexOf('v') !== -1) {
      subItems = [];
      subItems.push({ name: 'Adjustments (JV)', menuid: 'trans.jv', inProgress: true });
      subItems.push({ name: 'Disbursements (CV)', menuid: 'trans.cv', inProgress: true });
      subItems.push({ name: 'Recap Deposit', menuid: 'trans.recap', inProgress: true });
      subItems.push({ name: 'Cash Receipts (OR)', menuid: 'trans.or', inProgress: true });
      subItems.push({ name: 'Sales Book (PN)', menuid: 'trans.salesbook', inProgress: true });

      menuItems.push({
        name: 'Transactions', menuid: 'TransactionsMenu', icon: <i className="material-icons">receipt</i>,
        subItems: subItems
      });
    }

    if ((permissions.hasOwnProperty('LoanReports') && permissions.LoanReports.indexOf('f') !== -1) || permissions.LoanReports.indexOf('v') !== -1) {
      menuItems.push({ name: 'Loan Reports', menuid: 'LoanReports', icon: <i className="material-icons">shop</i> });
    }

    if ((permissions.hasOwnProperty('AccountingReports') && permissions.AccountingReports.indexOf('f') !== -1) || permissions.AccountingReports.indexOf('v') !== -1) {
      menuItems.push({
        name: 'Accounting Reports', menuid: 'AccountingReports', icon: <i className="material-icons">library_books</i>,
        subItems: [
          { name: 'CR Journal', menuid: 'actgrep.crj', inProgress: false },
          { name: 'CD Journal', menuid: 'actgrep.cdj', inProgress: true },
          { name: 'GL Report', menuid: 'actgrep.glreport', inProgress: true },
          { name: 'Balance Sheet', menuid: 'actgrep.balancesheet', inProgress: true },
          { name: 'Trial Balance', menuid: 'actgrep.trialbalance', inProgress: true },
          { name: 'Income Statement', menuid: 'actgrep.incomestatement', inProgress: true },
          { name: 'CF Statement', menuid: 'actgrep.cashflowstmt', inProgress: true },
          { name: 'Summ. of Coll.', menuid: 'actgrep.collsummary', inProgress: true },
          { name: 'LR Schedule', menuid: 'actgrep.lrsched', inProgress: true },
          { name: 'AA Schedule', menuid: 'actgrep.aasched', inProgress: true },
          { name: 'EPP Schedule', menuid: 'actgrep.eppsched', inProgress: true },
          { name: 'RF for PL Sched.', menuid: 'actgrep.rfplsched', inProgress: true },
          { name: 'Summ. of W Taxes', menuid: 'actgrep.wtaxsumm', inProgress: true },
          { name: 'Summ. of Mo. Gross Income', menuid: 'actgrep.mogincomesumm', inProgress: true },
          { name: 'Summ. of Mo. Expenses', menuid: 'actgrep.moexpensesumm', inProgress: true },
          { name: 'Summ. of Granted v Coll', menuid: 'actgrep.grantedvcollsumm', inProgress: true },
          { name: 'Audit Trail', menuid: 'actgrep.audittrail', inProgress: true },
          { name: 'Projected Exp. Budget', menuid: 'actgrep.projexpbudg', inProgress: true },
          { name: 'PN Listing', menuid: 'actgrep.pnlisting', inProgress: true },
        ]
      });
    }

    if ((permissions.hasOwnProperty('SAP') && (permissions.SAP.indexOf('f') !== -1)) || permissions.SAP.indexOf('v') !== -1) {
      menuItems.push({ name: 'SAP', menuid: 'SAP', icon: <i className="material-icons">swap_horizontal_circle</i> });
    }

    if ((permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1)) || permissions.Settings.indexOf('v') !== -1) {
      menuItems.push({
        name: 'Settings', menuid: 'Settings', icon: <i className="material-icons">settings</i>,
        subItems: [
          { name: 'Products', menuid: 'settings.products' },
          { name: 'Branches', menuid: 'settings.branches' },
          { name: 'Banks', menuid: 'settings.banks' },
          { name: 'Loan Charges', menuid: 'settings.loancharges' },
          { name: 'Users', menuid: 'settings.users' },
          { name: 'Roles', menuid: 'settings.roles' }
        ]
      });
    }

    switch (this.state.selectedMenu) {
      default:
      case '':
      case '/':
      case 'welcome':
        content = <Welcome Global={Global} parentComponent={this} inlineStyles={inlineStyles} />;
        break;
      case 'Clients':
        if (permissions.hasOwnProperty('Clients') && (permissions.Clients.indexOf('f') !== -1 || permissions.Clients.indexOf('v') !== -1)) {
          content = <Clients parentComponent={this} theme={theme} />;
        }
        break;
      case 'clients.edit':
        if (permissions.hasOwnProperty('Clients') && (permissions.Clients.indexOf('f') !== -1 || permissions.Clients.indexOf('u') !== -1)) {
          content = <EditClients parentComponent={this} theme={theme} />;
        }
        break;
      case 'clients.details':
        if (permissions.hasOwnProperty('Clients') && (permissions.Clients.indexOf('f') !== -1 || permissions.Clients.indexOf('v') !== -1)) {
          if (recId) {
            content = <ClientDetails parentComponent={this} theme={theme} />;
          } else {
            content = <Typography variant="error">Client not found</Typography>
          }
        }
        break;
      case 'clients.loandetails':
        if (permissions.hasOwnProperty('Clients') && (permissions.Clients.indexOf('f') !== -1 || permissions.Clients.indexOf('v') !== -1)) {
          if (this.state.loanid) {
            content = <LoanDetails App={this} parentComponent={this} theme={theme} loanid={this.state.loanid} />;
          } else {
            content = <Typography variant="error">Loan not found</Typography>
          }
        }
        break;
      case 'loans.processing':
        if (permissions.hasOwnProperty('LoanProcessing') && (permissions.LoanProcessing.indexOf('f') !== -1 || permissions.LoanProcessing.indexOf('v') !== -1)) {
          content = <Processing parentComponent={this} theme={theme} />;
        }
        break;
      case 'loans.forApproval':
        if (permissions.hasOwnProperty('LoanApproval') && (permissions.LoanApproval.indexOf('f') !== -1 || permissions.LoanApproval.indexOf('v') !== -1)) {
          content = <Approval parentComponent={this} theme={theme} />;
        }
        break;
      case 'loans.approved':
        if (permissions.hasOwnProperty('LoanApproved') && (permissions.LoanApproved.indexOf('f') !== -1 || permissions.LoanApproved.indexOf('v') !== -1)) {
          content = <Approved parentComponent={this} theme={theme} />;
        }
        break;
      case 'loans.granted':
        if (permissions.hasOwnProperty('LoanGranted') && (permissions.LoanGranted.indexOf('f') !== -1 || permissions.LoanGranted.indexOf('v') !== -1)) {
          content = <Granted parentComponent={this} theme={theme} />;
        }
        break;
      case 'loans.new':
        if (permissions.hasOwnProperty('Clients') && (permissions.Clients.indexOf('f') !== -1 || permissions.Clients.indexOf('u') !== -1)) {
          content = <NewLoan App={this} parentComponent={this} theme={theme} loanid={this.state.loanid} />;
        }
        break;
      case 'extendloan':
        if (permissions.hasOwnProperty('Clients') && (permissions.Clients.indexOf('f') !== -1 || permissions.Clients.indexOf('u') !== -1)) {
          if (this.state.loanid) {
            content = <ExtendLoan App={this} parentComponent={this} theme={theme} loanid={this.state.loanid} />;
          } else {
            content = <Typography variant="error">Loan not found</Typography>
          }
        }
        break;
      case 'cmc.consolidated':
        if (permissions.hasOwnProperty('CMCConsolidated') && (permissions.CMCConsolidated.indexOf('f') !== -1 || permissions.CMCConsolidated.indexOf('v') !== -1)) {
          content = <Consolidated parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.transactions':
        if (permissions.hasOwnProperty('CMCTransactions') && (permissions.CMCTransactions.indexOf('f') !== -1 || permissions.CMCTransactions.indexOf('v') !== -1)) {
          content = <Transactions parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.disbursements':
        if (permissions.hasOwnProperty('CMCDisbursements') && (permissions.CMCDisbursements.indexOf('f') !== -1 || permissions.CMCDisbursements.indexOf('v') !== -1)) {
          content = <Disbursements parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.adjustments':
        if (permissions.hasOwnProperty('CMCAdjustments') && (permissions.CMCAdjustments.indexOf('f') !== -1 || permissions.CMCAdjustments.indexOf('v') !== -1)) {
          content = <Adjustments parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.recap':
        if (permissions.hasOwnProperty('CMCRecapDeposits') && (permissions.CMCRecapDeposits.indexOf('f') !== -1 || permissions.CMCRecapDeposits.indexOf('v') !== -1)) {
          content = <RecapOfDeposits parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.banks':
        if (permissions.hasOwnProperty('CMCBanks') && (permissions.CMCBanks.indexOf('f') !== -1 || permissions.CMCBanks.indexOf('v') !== -1)) {
          content = <Banks parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.branchesbanks':
        if (permissions.hasOwnProperty('CMCBranchesBanks') && (permissions.CMCBranchesBanks.indexOf('f') !== -1 || permissions.CMCBranchesBanks.indexOf('v') !== -1)) {
          content = <Branches parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.branchBank':
        if (permissions.hasOwnProperty('CMCBranchesBanks') && (permissions.CMCBranchesBanks.indexOf('f') !== -1 || permissions.CMCBranchesBanks.indexOf('v') !== -1)) {
          content = <BranchBanks parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.banktransactions':
        if (permissions.hasOwnProperty('CMCBankTransactions') && (permissions.CMCBankTransactions.indexOf('f') !== -1 || permissions.CMCBankTransactions.indexOf('v') !== -1)) {
          content = <BankTransactions parentComponent={this} theme={theme} />;
        }
        break;
      case 'cmc.settings':
        if (permissions.hasOwnProperty('CMCSettings') && (permissions.CMCSettings.indexOf('f') !== -1 || permissions.CMCSettings.indexOf('v') !== -1)) {
          content = <CmcSettings parentComponent={this} theme={theme} />;
        }
        break;
      case 'gl.journal':
        if (permissions.hasOwnProperty('GLjournal') && (permissions.GLjournal.indexOf('f') !== -1 || permissions.GLjournal.indexOf('v') !== -1)) {
          content = <GeneralJournal parentComponent={this} theme={theme} />;
        }
        break;
      case 'gl.account':
        if (permissions.hasOwnProperty('GLaccount') && (permissions.GLaccount.indexOf('f') !== -1 || permissions.GLaccount.indexOf('v') !== -1)) {
          content = <GLaccount parentComponent={this} theme={theme} />;
        }
        break;      
      case 'gl.acctchart':
        if (permissions.hasOwnProperty('GLchartofaccount') && (permissions.GLchartofaccount.indexOf('f') !== -1 || permissions.GLchartofaccount.indexOf('v') !== -1)) {
          content = <AccountGroups parentComponent={this} theme={theme} />;
        }
        break;
      case 'gl.tranjournal':
        if (permissions.hasOwnProperty('GLTransactionJournal') && (permissions.GLTransactionJournal.indexOf('f') !== -1 || permissions.GLTransactionJournal.indexOf('v') !== -1)) {
          content = <TransactionsJournal parentComponent={this} theme={theme} />;
        }
        break;        
      case 'actgrep.crj':
          if (permissions.hasOwnProperty('AccountingReports') && (permissions.AccountingReports.indexOf('f') !== -1 || permissions.AccountingReports.indexOf('v') !== -1)) {
            content = <crJournal parentComponent={this} theme={theme} />;
          }
          break;  
      case 'settings.branches':
        if (permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1 || permissions.Settings.indexOf('v') !== -1)) {
          content = <Branches parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.banks':
        if (permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1 || permissions.Settings.indexOf('v') !== -1)) {
          content = <Banks parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.loancharges':
        if (permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1 || permissions.Settings.indexOf('v') !== -1)) {
          content = <LoanCharges parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.products':
        if (permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1 || permissions.Settings.indexOf('v') !== -1)) {
          content = <Products parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.users':
        if (permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1 || permissions.Settings.indexOf('v') !== -1)) {
          content = <Users parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.roles':
        if (permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1 || permissions.Settings.indexOf('v') !== -1)) {
          content = <Roles parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.edituser':
        if (permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1 || permissions.Settings.indexOf('v') !== -1)) {
          content = <Edituser parentComponent={this} theme={theme} />;
        }
        break;
      case 'settings.editrole':
        if (permissions.hasOwnProperty('Settings') && (permissions.Settings.indexOf('f') !== -1 || permissions.Settings.indexOf('v') !== -1)) {
          content = <EditRole parentComponent={this} theme={theme} />;
        }
        break;
      case 'user.profile':
        content = <MyProfile parentComponent={this} theme={theme} />;
        break;
    }
    if (!content) {      
      // If it's supposed to be valid, MAKE SURE selectedMenu is initialized in state.permissions
      content = <NotFound />;
    }
    var itemKey = 0;
    var profileId = 0;
    if (userdata.hasOwnProperty('userdata') && userdata.userdata.hasOwnProperty('id')) {
      profileId = userdata.userdata.id;
    }

    return (
      <Box className={"maincontainer"}>
        <MuiThemeProvider theme={theme}>
          {userdata.userdata &&
            <Fragment>
              <CssBaseline />

              <AppBar position="fixed" style={inlineStyles.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    style={inlineStyles.menuButton}
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={() => this.handleLeftDrawer()}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Typography variant="h6" noWrap>
                    <Link to="/" onClick={() => this.selectMenu('')} style={inlineStyles.linked}>{process.env.REACT_APP_NAME}</Link>
                  </Typography>
                  <div style={inlineStyles.spacer}></div>

                  <div style={inlineStyles.searchBox}>
                    <Box style={inlineStyles.flexSearchBox}>
                      <TextField
                        select
                        value={this.state.searchTable}
                        onChange={e => this.setSearchTable(e.target.value)}
                        style={inlineStyles.selectInSearchBox}
                        disabled={this.state.isLoading}
                        className="topbarSelect"
                      >
                        <MenuItem value='Client'>Search Client</MenuItem>
                        <MenuItem value='PN'>Search PN</MenuItem>
                      </TextField>
                      <InputBase
                        placeholder={" Enter " + this.state.searchTable + " here"}
                        style={inlineStyles.flexSearchInput}
                        value={this.state.searchTableVal}
                        inputProps={{ 'aria-label': 'Search' }}
                        onChange={e => this.setSearchVal(e.target.value)}
                        onKeyPress={(event) => {
                          /* when space or enter key is pressed */
                          if (event.charCode === 13) {
                            this.startSearch();
                          }
                        }}
                      />
                      {this.state.searchTableVal &&
                        <IconButton style={inlineStyles.homeClearSearchIcon} onClick={() => this.clearSearch()} aria-label="Clear Search">
                          <Icon className='fas fa-times-circle' style={inlineStyles.smallestIcon} />
                        </IconButton>
                      }

                    </Box>
                  </div>
                  <TextField
                    select
                    value={this.state.branchId ? this.state.branchId : 0}
                    onChange={e => this.setBranch(e.target.value)}
                    style={inlineStyles.select}
                    className="topbarSelect"
                    disabled={this.state.isLoading}
                  >
                    <MenuItem value={0}>All branches</MenuItem>
                    {this.state.branches && this.state.branches.length > 0 && this.state.branches.map((row, index) => {
                      return (
                        row.active === "1" &&
                        <MenuItem key={index} value={row.key}>{row.value}</MenuItem>
                      )
                    })
                    }

                  </TextField>

                  <IconButton
                    style={inlineStyles.toolbarButtons}
                    aria-label="0 notifications"
                    color="inherit">
                    <Badge badgeContent={0} color="secondary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="Account of current user"
                    aria-controls={this.state.menuId}
                    aria-haspopup="true"
                    onClick={this.handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Typography style={{ 'marginLeft': 6, 'fontSize': '80%' }}>{userdata.userdata.firstname}</Typography>
                </Toolbar>
              </AppBar>
              <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={this.state.menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.anchorEl ? true : false}
                onClose={this.handleMenuClose}
              >
                <MenuItem onClick={() => { this.handleMenuClose(); this.selectMenu('user.profile', { idFor: 'selectedID', value: profileId }); }}>Change Password</MenuItem>
                <MenuItem onClick={this.logout}>Logout</MenuItem>
              </Menu>
              <Drawer
                style={inlineStyles.drawer}
                variant="persistent"
                anchor="left"
                open={this.state.leftDrawerOpen}
                classes={{
                  paper: 'drawerPaper',
                }}
              >
                <div style={inlineStyles.toolbar} />
                <List component="nav" aria-label="Main mailbox folders">
                  {menuItems.map((row) => {
                    itemKey++;
                    var collapsedMenu;
                    switch (row.menuid) {
                      case 'Loans':
                        collapsedMenu = this.state.collapsed_loans;
                        break;
                      case 'LoanReports':
                        collapsedMenu = this.state.collapsed_loanreports;
                        break;
                      case 'AccountingReports':
                        collapsedMenu = this.state.collapsed_actgrep;
                        break;
                      case 'CashMovement':
                        collapsedMenu = this.state.collapsed_cmc;
                        break;
                      case 'TransactionsMenu':
                        collapsedMenu = this.state.collapsed_trans;
                        break;
                      case 'GeneralLedger':
                        collapsedMenu = this.state.collapsed_gl;
                        break;
                      default:
                        collapsedMenu = this.state.collapsed_settings;
                        break;
                    }

                    return (
                      <Fragment key={itemKey}>
                        {!row.subItems &&
                          <ListItem button style={inlineStyles.listitem} onClick={() => this.selectMenu(row.menuid)}>
                            <ListItemIcon style={inlineStyles.itemIcon}>{row.icon}</ListItemIcon>
                            <ListItemText primary={row.name} />
                          </ListItem>
                        }
                        {
                          row.subItems &&
                          <Fragment>
                            <ListItem button onClick={() => this.handleCollapseMenu(row.menuid)} style={inlineStyles.listitem}>
                              <ListItemIcon style={inlineStyles.itemIcon}>{row.icon}</ListItemIcon>
                              <ListItemText primary={row.name} />
                              {collapsedMenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse component="li" in={collapsedMenu} timeout="auto" unmountOnExit>
                              <List style={inlineStyles.sublistitem}>
                                {row.subItems.map((subrow) => {
                                  itemKey++;
                                  return (
                                    <ListItem key={itemKey}
                                      button style={inlineStyles.nested} onClick={() => this.selectMenu(subrow.menuid)}>
                                      {!subrow.inProgress &&
                                        <i className="material-icons">arrow_right</i>
                                      }
                                      {subrow.inProgress &&
                                        <ListItemIcon style={inlineStyles.itemIcon}><img alt="Under construction" src={underConstruction} height={14} /></ListItemIcon>
                                      }
                                      <ListItemText primary={subrow.name} />

                                    </ListItem>
                                  )
                                })
                                }

                              </List>
                            </Collapse>
                          </Fragment>
                        }
                      </Fragment>
                    )
                  })
                  }

                </List>
              </Drawer>
              <main
                style={this.state.leftDrawerOpen === true ? inlineStyles.contentShift : inlineStyles.content}
              >
                <div style={inlineStyles.drawerHeader} />

                {content}

              </main>
            </Fragment>
          }
          {!userdata.userdata &&
            <Fragment>
              <SignIn />
            </Fragment>
          }
        </MuiThemeProvider>
      </Box>
    );
  }
}
