import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../utils/SnackerBar';
import { Global, inlineStyles } from '../../utils/global';
import { EnhancedTableToolbar, EnhancedTableHead } from '../../utils/common_page_with_table';
import { Box, Grid, Table, TableBody, TableRow, TableCell, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

import 'date-fns';
import '../../styles.css';

const url = 'cash/transcategory.php';

export default class TransCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            addNew: false,
            deleteDialogOpen: false,
            notification: '',
            isLoading: true,
            isDataLoading: false,
            selectedID: 0,
            objName: '',
            schema: [],
            order: 'asc',
            orderBy: 'transCatName',
            page: 0,
            pageTotal: 0,
            rows: [],
            rowsPerPage: 100,
            searchFor: '',
            selected: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            url: props.parentComponent.state.API_HOST+url,
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
        return;
    };
    handleRequestSort = (event, property) => {
        var isDesc = this.state.order === 'desc';
        var order = isDesc || this.state.orderBy !== property ? 'asc' : 'desc';
        var values = {
            order: order,
            orderBy: property,
        };
        this.setComponentState('order', order);
        this.setComponentState('orderBy', property);
        Global.loadList(this, values);
    }
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    addNew = (v) => {
        this.setState({ addNew: v });
    }
    editRow = (row) => {
        this.setState( {id: row.transCatID});
        Global.setStateValue(this,'transCatName',row.transCatName);
        this.addNew(true);
    }
    confirmDeleteRow(row) {
        this.setState({ id: row.transCatID });        
        this.setState({ objName: row.transCatName });
        this.setDeleteDialogOpen(true);
    }
    confirmDeletion = (id) => {
        Global.deleteRow(this, id);
    }
    setDeleteDialogOpen = (v) => {
        this.setState({ deleteDialogOpen: v });          
        if (!v) {            
            Global.loadList(this, {isDataLoading: false});
        }
    }
    actionButtons = () => {
        return (            
            <Box style={inlineStyles.toolbarActionBox}>
                <Button onClick={() => { this.addNew(true) }} style={inlineStyles.buttonOrange} variant='contained' size="small">
                    <Icon className="fas fa-plus" style={inlineStyles.smallIcon}></Icon>Add Trans Category
                </Button>
            </Box>
        )
    }
    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            var newSelecteds = this.state.rows.map(n => n.name);
            this.setComponentState('selected', newSelecteds);
            return;
        }
        this.setComponentState('selected', []);
    }

    handleChangePage = (event, newPage) => {
        this.setComponentState('page', newPage);        
        Global.loadList(this, {page: newPage});
    }

    handleChangeRowsPerPage = (event) => {
        this.setComponentState('rowsPerPage', event.target.value);
        Global.loadList(this, {rowsPerPage: event.target.value});
    }
    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, false); /* if second parameter is true, the record to edit will be loaded (getOneRow()); else list will be loaded */
        }
    }
    render() {
        const reportTitle = ''
        var values = this.state.schema;
        var headRows = [];
        values.forEach((row) => {
            headRows.push({ id: row.name, label: row.label, numeric: row.numeric, showInList: row.showInList });
        });
        headRows.push({ id: 'action', label: 'Actions', numeric: false, showInList: true });
        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }

                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={0}
                                toolbarTitle={reportTitle}
                                actionButtons={this.state.addNew ? '' : this.actionButtons}
                                t={this}
                                Global={Global}
                            />
                            {this.state.addNew &&
                                <Fragment>
                                    <form autoComplete="off" style={inlineStyles.editform}>
                                        <Grid container spacing={2}>
                                            {values.filter((row) => row.type !== 'hidden').map((row, index) => {
                                                return (
                                                    <Grid item xs={5} key={index}>
                                                        <TextField
                                                            error={row.error ? true : false}
                                                            autoFocus={row.name==='transCatName' ? true : false}
                                                            variant="outlined"
                                                            margin="dense"
                                                            name={row.name}
                                                            required={row.required}
                                                            label={row.error ? row.error : row.label}
                                                            value={row.value}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleTextfieldChange(row.name)}
                                                            disabled={this.state.isDataLoading}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                            }
                                            <Grid item xs={2} style={{paddingTop: 15}}>
                                                <Button onClick={Global.handleSaveForm(this)} style={inlineStyles.button} color="primary" variant='contained' size="small">
                                                    <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Fragment>
                            }
                            <div style={inlineStyles.tableWrapper}>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size='small'
                                >

                                    <EnhancedTableHead
                                        numSelected={this.state.selected ? this.state.selected.length : 0}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        headRows={headRows}
                                    />
                                    <TableBody>
                                        {this.state.rows && this.state.rows.length > 0 && this.state.rows.map((row, index) => {
                                            const labelId = `table-row-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                >
                                                    <TableCell className="listRowNum" id={labelId} scope="row" padding="none" align="center">
                                                        {index + 1 + (this.state.page * this.state.rowsPerPage)}
                                                    </TableCell>
                                                    {values.filter(col => col.showInList).map((col, colindex) => {
                                                        return (
                                                            <TableCell key={colindex} align={col.numeric ? 'right' : (col.align ? col.align : 'left')}>
                                                                {row[col.name]}
                                                            </TableCell>
                                                        );
                                                    })
                                                    }

                                                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                                        <IconButton style={inlineStyles.iconButton} onClick={() => this.editRow(row)} aria-label="Edit row">
                                                            <Icon className={'fas fa-edit fa-small '} color="primary" />
                                                        </IconButton>
                                                        <IconButton style={inlineStyles.iconButton} onClick={() => this.confirmDeleteRow(row)} aria-label="Delete row">
                                                            <Icon className={'fas fa-trash-alt fa-small'} color="error" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        }
                                        {this.state.rows && !this.state.rows.length &&
                                            <TableRow><TableCell align="center" colSpan={8}>{"No result"}</TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>

                        </Fragment>
                    }

                </Paper>                
                <Dialog
                    open={this.state.deleteDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description"></DialogContentText>
                        <div style={{ padding: 15 }}>
                            Do you want to continue deleting "{this.state.objName}"?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setDeleteDialogOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            No
                    </Button>
                        <Button onClick={() => this.confirmDeletion(this.state.id)} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                            Yes
                    </Button>
                    </DialogActions>
                </Dialog>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}

