import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../utils/SnackerBar';
import { Global, inlineStyles } from '../../utils/global';
import { Grid, Table, TableBody, TableRow, TableCell, TableHead, TableFooter, Checkbox, IconButton, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, MenuItem } from '@material-ui/core';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import 'date-fns';
import '../../styles.css';
import EditClients from './EditClients';
import TextAutoSearch from './TextAutoSearch';
import DraftEditor from '../../forms/Editor';
import FileUploader from '../../utils/FileUploader';
import CollateralImages from '../loans/CollateralImages';
import { numberFormat } from '../../utils/common_utils';
import OtherLoans from '../loans/OtherLoans';
const url = process.env.REACT_APP_CLIENT_LOAN_DETAILS_URL;

export default class LoanDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            notification: '',
            dialogNotification: '',
            isLoading: true,
            isDataLoading: false,
            isDialogDataLoading: false,
            isShowingPdf: false,
            clientPhoto: null,
            client: {},
            collaterals: {},
            checkVoucher: { cvno: '', explanation: '--', payee: '--', bank: '', checkno: '' },
            branchId: 0,
            deleteDialogOpen: false,
            dialogAddEditOpen: false,
            dialogUploadOpen: false,
            dialogAssignPNOpen: false,
            dialogCreateCVOpen: false,
            isDialogDataError: false,
            assignedPN: '',
            selectedTable: '',
            editPNremarks: false,
            editFormSchema: [],
            getLoanFees: true,
            editMode: false,
            fees: {},
            loanDetails: [],
            objName: '',
            schema: [],
            schema_edit: [],
            rows: [],
            ledger: {},
            comaker: {},
            cireport: {},
            requirements: {},
            loanremarks: {},
            reloadPage: false,
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            selectedTabIndex: 2,
            selectedId: 0,
            searchVal: '',
            searchStarted: false,
            selectedIdFromDialog: 0,
            tabkey: 0,
            schemaUrl: process.env.REACT_APP_SCHEMAS_URL,
            url: props.parentComponent.state.API_HOST + url,
            approvedAmount: 0,
            approvedExtendedTerm: 0,
            approvedTerm: 0,
            approvedRemarks: '',
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    printLoanInfo = () => {
        Global.showPdf(this, this.props.parentComponent.state.API_HOST + process.env.REACT_APP_PDF_LOAN_INFO_URL, 'letter', 'portrait');
    }
    printCV = () => {
        Global.showPdf(this, this.props.parentComponent.state.API_HOST + 'loans/printcv.php', 'letter', 'portrait');
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
    };
    handleCollateralfieldChange = (name) => event => {
        var collaterals = this.state.loanDetails.collaterals
        var values = { ...collaterals };
        // eslint-disable-next-line
        collaterals.find((o, i) => {
            if (o.collateralname === name) {
                values[i].value = event.target.value;
                return true;
            }
        });
        this.setState({ values });
    };
    changeApprovalValue = (name) => event => {
        this.setState({ [name]: event.target.value });
    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    setCVState = (sState, vState) => {
        var checkVoucher = { ...this.state.checkVoucher };
        checkVoucher[sState] = vState;
        this.setState({ checkVoucher });
    }
    setClientPhoto = (imgSrc) => {
        var client = this.state.client;
        client.image = imgSrc;
        this.setState({ client });
    }
    handleTabChange = (event, newVal) => {
        if (typeof newVal == 'number') {
            this.handleChangeTabIndex(newVal);
        }
    }
    onSelectSetSubmitted = (id) => {
        if (!id) return;
        var values = [];
        values.push({ name: 'id', value: id, required: true });
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: true });
        let url = this.props.parentComponent.state.API_HOST + 'common/save.php';
        Global.SaveDialogFormData(this, url, values, 'requirement');
    }
    confirmDeleteRow(id, name, tabName) {
        var states = this.state;
        states.selectedId = id;
        states.objName = name;
        states.selectedTable = tabName;
        this.setState({ states });
        this.setDeleteDialogOpen(true);
    }
    confirmDeletion = (id) => {
        Global.deleteRowByTab(this, id);
    }
    setDeleteDialogOpen = (v) => {
        this.setState({ deleteDialogOpen: v });
    }
    setEditDialogOpen = (v, tabname, editContent) => {
        var states = this.state;
        if (v) {
            /* get the schema to load in edit form */
            if (tabname === 'CI Report' || tabname === 'Loan Remarks') {
                states.openDraftEditor = v;
            }
            Global.getSchemaForDialog(this, tabname)
            states.isDialogDataLoading = true;
            states.objName = tabname;
        } else {
            states.objName = '';
            states.openDraftEditor = false;
        }
        states.dialogAddEditOpen = v;
        this.setState({ states });
    }
    setDialogUploadOpen = (v) => {
        if (!v) {
            this.setState({ ...this.state, dialogUploadOpen: v, reloadPage: true });
        } else {
            this.setState({ dialogUploadOpen: v });
        }
    }
    setDialogAssignPNOpen = (v) => {
        this.setState({ dialogAssignPNOpen: v });
    }
    setOpenEditor = (v, tabname, editContent) => {
        this.setEditDialogOpen(v, tabname, editContent);
    }
    saveCIReport = (htmlValue) => {
        var values = [];
        if (this.state.loanDetails.hasOwnProperty('cireport') && this.state.loanDetails.cireport.cidetailID) {
            values.push({ name: 'id', value: this.state.loanDetails.cireport.cidetailID, required: false });
        } else {
            values.push({ name: 'id', value: 0, required: false });
        }
        values.push({ name: 'value', value: htmlValue, required: false });
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: true });
        let url = this.props.parentComponent.state.API_HOST + 'common/save.php';
        Global.SaveDialogFormData(this, url, values, 'ci_details', this.updateCIReportValue(htmlValue));
    }
    updateCIReportValue = (htmlValue) => {
        var states = this.state;
        if (states.loanDetails.hasOwnProperty('cireport') && states.loanDetails.cireport.hasOwnProperty('value')) {
            states.loanDetails.cireport.value = htmlValue;
        } else {
            states.loanDetails.cireport = { value: htmlValue };
        }
        states.openDraftEditor = false;

        this.setState({ states });
    }
    addNew = (tabname) => {
        this.setEditDialogOpen(true, tabname, false);
    }
    handleChangeTabIndex = (newVal) => {
        this.setState({ selectedTabIndex: newVal });
    }
    saveCoMaker = (clientId) => {
        var values = [];
        if (typeof clientId !== 'undefined') {
            values.push({ name: 'clientID', value: clientId, required: true });
        } else {
            values.push({ name: 'clientID', value: this.state.selectedIdFromDialog, required: true });
        }
        values.push({ name: 'loanID', value: this.props.parentComponent.state.loanid, required: true });
        let url = this.props.parentComponent.state.API_HOST + 'common/save.php';
        Global.SaveDialogFormData(this, url, values, 'comaker');
    }
    saveCollateral = (clientId) => {
        var values = [];
        if (typeof clientId !== 'undefined') {
            values.push({ name: 'clientID', value: clientId, required: true });
        } else {
            values.push({ name: 'clientID', value: this.state.selectedIdFromDialog, required: true });
        }
        values.push({ name: 'loanID', value: this.props.parentComponent.state.loanid, required: true });
        if (this.state.loanDetails.hasOwnProperty('collaterals')) {
            var collaterals = JSON.stringify(this.state.loanDetails.collaterals);
            values.push({ name: 'collaterals', value: collaterals, required: false });
        }
        let url = this.props.parentComponent.state.API_HOST + 'common/save.php';
        Global.SaveDialogFormData(this, url, values, 'collateral');
    }
    setListData = (tabname, listdata) => {
        var states = this.state;
        if (tabname === 'comaker') {
            states.loanDetails.comaker = listdata;
            this.setState({ states });
        }
    }
    saveClientDialogForm = (formSchema) => {
        let url = this.props.parentComponent.state.API_HOST + 'common/save.php';
        Global.SaveDialogFormData(this, url, formSchema, 'client', this.saveCoMaker);
    }
    getComaker = () => {
        var values = [];
        values.push({ name: 'clientID', value: this.state.selectedIdFromDialog, required: true });
        values.push({ name: 'loanID', value: this.props.parentComponent.state.loanid, required: true });
        let url = this.props.parentComponent.state.API_HOST + 'common/get.php';
        Global.getList(this, url, this.props.parentComponent.state.loanid, 'comaker');
    }
    handleCheckboxClick = (event, reqID) => {
        var loanDetails = this.state.loanDetails;
        if (loanDetails.hasOwnProperty('submittedRequirements')) {
            var values = { ...loanDetails.submittedRequirements };
            // eslint-disable-next-line
            loanDetails.submittedRequirements.find((o, i) => {
                if (o.reqID === reqID) {
                    values[i].submitted = values[i].submitted === 1 || values[i].submitted === '1' ? '0' : '1';
                    values[i].dateSubmitted = values[i].submitted === '1' ? new Date().toISOString().split('T')[0] : '';
                    return true;
                }
            });
            this.setState({ values });
        }

    }

    isSelected = (reqID) => {
        var loanDetails = this.state.loanDetails;
        var retVal = false;
        if (loanDetails.hasOwnProperty('submittedRequirements')) {
            // eslint-disable-next-line
            loanDetails.submittedRequirements.find((o, i) => {
                if (o.reqID === reqID && (o.submitted === '1' || o.submitted === 1)) {
                    retVal = true;
                    return true;
                }
            });
        }

        return retVal;
    }
    extendLoan = () => {
        this.props.parentComponent.selectMenu('extendloan', 0);
    }
    submitForApproval = () => {
        var values = [];
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: false });
        let url = this.props.App.state.API_HOST + 'loans/loanapplications.php';
        Global.SaveDialogFormData(this, url, values, 'submitForApproval', this.loadData);
    }
    cancelLoan = () => {
        var values = [];
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: false });
        let url = this.props.App.state.API_HOST + 'loans/loanapplications.php';
        if (this.props.App.state.clientID > 0) {
            Global.SaveDialogFormData(this, url, values, 'cancelLoan', this.gotoClient);
        } else {
            Global.SaveDialogFormData(this, url, values, 'cancelLoan', this.gotoLoans);
        }
    }
    approveLoan = () => {
        var values = [];
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: true });
        values.push({ name: 'approvedAmount', value: this.state.approvedAmount, required: true });
        values.push({ name: 'approvedTerm', value: this.state.approvedTerm, required: true });
        values.push({ name: 'approvedExtendedTerm', value: this.state.approvedExtendedTerm, required: false });
        values.push({ name: 'approvedRemarks', value: this.state.approvedRemarks, required: false });
        let url = this.props.App.state.API_HOST + 'loans/loanapplications.php';
        Global.SaveDialogFormData(this, url, values, 'approveLoan', this.loadData);
    }
    declineLoan = () => {
        var values = [];
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: false });
        let url = this.props.App.state.API_HOST + 'loans/loanapplications.php';
        Global.SaveDialogFormData(this, url, values, 'declineLoan', this.gotoClient);
    }
    revokeLoan = () => {
        var values = [];
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: false });
        let url = this.props.App.state.API_HOST + 'loans/loanapplications.php';
        Global.SaveDialogFormData(this, url, values, 'revokeLoan', this.gotoClient);
    }
    gotoClient = () => {
        this.props.parentComponent.selectMenu('clients.details');
    }
    gotoLoans = () => {
        this.props.parentComponent.selectMenu('loans.processing');
    }
    assignPN = () => {
        var values = [];
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: true });
        values.push({ name: 'PN', value: this.state.assignedPN, required: false });
        let url = this.props.App.state.API_HOST + 'loans/loanapplications.php';
        Global.SaveDialogFormData(this, url, values, 'assignPN', this.afterAssignPN);
    }
    afterAssignPN = () => {
        this.setState({ dialogAssignPNOpen: false });
        this.loadData();
    }
    setCreateCVOpen = (v) => {
        this.setState({ dialogCreateCVOpen: v });
    }
    createCV = (netproceeds, totalfees, gl_account) => {
        var values = [];
        values.push({ name: 'loanid', value: this.props.parentComponent.state.loanid, required: true });
        values.push({ name: 'payee', value: this.state.checkVoucher.payee, required: false });
        values.push({ name: 'explanation', value: this.state.checkVoucher.explanation, required: false });
        values.push({ name: 'bank', value: this.state.checkVoucher.bank, required: false });
        values.push({ name: 'cvno', value: this.state.checkVoucher.cvno, required: true });
        values.push({ name: 'totalFees', value: totalfees, required: true });
        values.push({ name: 'netproceeds', value: netproceeds, required: true });
        values.push({ name: 'gl_account', value: gl_account, required: true });
        values.push({ name: 'checkno', value: this.state.checkVoucher.checkno, required: true });
        values.push({ name: 'action', value: 'createCV', required: false });

        let url = this.props.App.state.API_HOST + 'loans/loanapplications.php';
        Global.SaveDialogFormData(this, url, values, 'createCV', this.afterCreateCV);
    }
    afterCreateCV = () => {
        this.setState({ dialogCreateCVOpen: false });
        this.loadData();
    }
    disclosure = () => {
        Global.showPdf(this, this.props.parentComponent.state.API_HOST + 'loans/pdf-disclosure.php', 'letter', 'portrait');
    }
    promisoryNote = () => {
        Global.showPdf(this, this.props.parentComponent.state.API_HOST + 'loans/pdf-promisoryNote.php', 'letter', 'portrait');
    }
    computationSheet = () => {
        Global.showPdf(this, this.props.parentComponent.state.API_HOST + 'loans/pdf-computationSheet.php', 'letter', 'portrait');
    }
    RFPLmonitoring = () => {
        Global.showPdf(this, this.props.parentComponent.state.API_HOST + 'loans/pdf-RFPLmonitoring.php', 'letter', 'portrait');
    }
    RFPLagreement = () => {
        Global.showPdf(this, this.props.parentComponent.state.API_HOST + 'loans/pdf-RFPLagreement.php', 'letter', 'portrait');
    }
    deedOfUndertaking = () => {
        Global.showPdf(this, this.props.parentComponent.state.API_HOST + 'loans/pdf-deed.php', 'letter', 'portrait');
    }
    loadData = () => {
        if (this.props.parentComponent.state.snackBarMsg.msg) {
            var propSnackBar = this.props.parentComponent.state.snackBarMsg;
            this.openSnackBar(propSnackBar.msg, propSnackBar.type, true);
            this.props.parentComponent.setState({ snackBarMsg: { msg: '', type: '' } });
        }
        Global.getSchema(this, true); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */
        Global.getDataRows(this, this.props.parentComponent.state.API_HOST + process.env.REACT_APP_CLIENTS_URL, 'client', 'select');
        Global.getDataRows(this, this.props.parentComponent.state.API_HOST + process.env.REACT_APP_CLIENT_LOAN_DETAILS_URL, 'loanDetails', 'details', [{ name: 'loanid', value: this.props.loanid }]);

        this.setState({ id: this.props.loanid });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loanid !== this.props.loanid) {
            this.loadData();
        }
        var loanDetails = this.state.loanDetails;
        if (this.state.approvedAmount === 0 && loanDetails.hasOwnProperty('loaninfo') && loanDetails.loaninfo.status) {
            this.setState({ ...this.state, approvedAmount: loanDetails.loaninfo.AmountApplied, approvedTerm: loanDetails.loaninfo.Term });
        }
    }
    componentDidMount() {
        this.loadData();
    }

    render() {
        var loanDetails = this.state.loanDetails;
        var values = this.state.rows;
        var client = this.state.client;
        var clientPhoto = client.image;
        var transactions = {};
        var ledger = {};
        var collaterals = {};
        var comaker = {};
        var cireport = { value: '' };
        var loanremarks = {};
        var submittedRequirements = {};
        var banks = [];
        var fees = {};
        var feeNameByGL = {};
        if (loanDetails.hasOwnProperty('transactions') && loanDetails.transactions) {
            transactions = loanDetails.transactions;
        }
        if (loanDetails.hasOwnProperty('ledger') && loanDetails.ledger) {
            ledger = loanDetails.ledger;
        }
        if (loanDetails.hasOwnProperty('collaterals') && loanDetails.collaterals) {
            collaterals = loanDetails.collaterals;
        }
        if (loanDetails.hasOwnProperty('comaker') && loanDetails.comaker) {
            comaker = loanDetails.comaker;
        }
        if (loanDetails.hasOwnProperty('cireport') && loanDetails.cireport) {
            cireport = loanDetails.cireport;
        }
        if (loanDetails.hasOwnProperty('loanremarks') && loanDetails.loanremarks) {
            loanremarks = loanDetails.loanremarks;
        }
        if (loanDetails.hasOwnProperty('banks') && loanDetails.banks) {
            banks = loanDetails.banks;
        }
        if (loanDetails.hasOwnProperty('feeNameByGL') && loanDetails.feeNameByGL) {
            feeNameByGL = loanDetails.feeNameByGL;
        }

        if (loanDetails.hasOwnProperty('submittedRequirements') && loanDetails.submittedRequirements) {
            submittedRequirements = loanDetails.submittedRequirements;
        } else if (loanDetails.hasOwnProperty('requirements')) {
            submittedRequirements = loanDetails.requirements;
            loanDetails.submittedRequirements = submittedRequirements;
            this.setState({ loanDetails });
        }
        var totalFees = 0;
        if (loanDetails.hasOwnProperty('fees')) {
            fees = loanDetails.fees;
            if (fees.length > 0) {
                fees.forEach((row, index) => {
                    totalFees += parseFloat(row.value);
                })
            }
        }
        var objName = typeof this.state.objName !== 'undefined' ? this.state.objName : '';

        var editorContent = '';
        if (typeof objName === 'string' && objName === 'CI Report') {
            editorContent = cireport.hasOwnProperty('value') ? cireport.value : '';
        }

        var statusColor = '#cccccc';
        var loanStatus = '';
        var productCode = '';
        var PN = '';
        var netproceeds = 0;
        var AmountApplied = 0;
        var totalCVFees = 0;
        var glAccount = '';

        if (loanDetails.hasOwnProperty('loaninfo') && loanDetails.loaninfo.status) {
            loanStatus = loanDetails.loaninfo.status.toLowerCase();
            productCode = loanDetails.loaninfo.productCode.toUpperCase();
            PN = loanDetails.loaninfo.PN;
            netproceeds = parseFloat(loanDetails.loaninfo.netproceeds);
            AmountApplied = parseFloat(loanDetails.loaninfo.AmountApplied);

            var setCheckVoucher = false;
            if (this.state.checkVoucher.payee === '--') {
                setCheckVoucher = true;
            }
            if (this.state.checkVoucher.explanation === '--') {
                setCheckVoucher = true;
            }
            if (setCheckVoucher) {
                var checkVoucher = { ...this.state.checkVoucher };
                var payee = loanDetails.loaninfo.payee;
                checkVoucher['payee'] = payee;
                checkVoucher['explanation'] = 'Net Proceeds of ' + payee;
                this.setState({ checkVoucher });
            }
            switch (loanStatus) {
                case "processing":
                    statusColor = "#dc3545";
                    break;
                case "approved":
                    statusColor = "#28a745";
                    break;
                case "approval":
                case "closed":
                case "release":
                    statusColor = "#ffa500";
                    break;
                case "cancel":
                case "canceled":
                case "declined":
                    statusColor = "#6c757d";
                    break;

                case "granted":
                case "ci":
                case "current":
                case "past due":
                    statusColor = "#17a2b8";
                    break;
                default:
                    statusColor = "#007bff";
            }
            var baseTerm = parseInt(loanDetails.loaninfo.Term ? loanDetails.loaninfo.Term : '0') - parseInt(loanDetails.loaninfo.extension ? loanDetails.loaninfo.extension : '0');
            var maxTerm = parseInt(loanDetails.loaninfo.maxTerm ? loanDetails.loaninfo.maxTerm : '0') - baseTerm;
            maxTerm = !maxTerm ? 6 : maxTerm;
            var terms = [];
            for (let i = 0; i <= maxTerm; i++) {
                terms.push({ key: i, value: i + ' months' });
            }

        }

        return (
            <Fragment>
                <Paper>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={9} sm={8}>
                            {
                                this.state.notification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                                    {this.state.notification}
                                </Typography>
                            }
                            {
                                this.state.isDataLoading &&
                                <Fragment>
                                    <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                        {this.props.parentComponent.loadingCircular()}
                                    </div>
                                </Fragment>
                            }
                            <div style={Object.assign({}, { display: 'flex' }, inlineStyles.sectionHeaderBgColor)}>
                                <div style={Object.assign({}, { flex: '0 1 auto' }, inlineStyles.sectionHeaderPads, inlineStyles.sectionHeaderSize)}>
                                    Loan Information
                            </div>
                                <div style={{ flex: '1 1 auto' }}></div>
                                <div style={{ flex: '0 1 auto' }}>
                                    {!this.state.isShowingPdf &&
                                        <Button onClick={() => this.printLoanInfo()} style={{ margin: 5, padding: "0 5px" }} color="primary" variant='outlined' size="small">
                                            <Icon className="fas fa-print" style={inlineStyles.smallIcon}></Icon>Print Form
                                        </Button>                                        
                                    }
                                    {!this.state.isShowingPdf && loanStatus === 'granted' &&
                                        <Button onClick={() => this.printCV()} style={{ margin: 5, padding: "0 5px" }} color="primary" variant='contained' size="small">
                                                 <Icon className="fas fa-print" style={inlineStyles.smallIcon}></Icon>Print CV
                                        </Button> 
                                    }
                                    {this.state.isShowingPdf && <Typography color="secondary" style={{ padding: 3 }}> Opening pdf report ...</Typography>}
                                </div>
                            </div>

                            {!this.state.isDataLoading &&
                                <Table size="small">
                                    <TableBody>
                                        <TableRow style={{ fontWeight: 'bold' }}>
                                            <TableCell style={{ fontWeight: 'bold' }} colSpan={3}><div style={{ fontSize: 14 }}>Client: {client.firstName} {client.LastName}</div></TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }} align="right" colSpan={3}><div style={{ fontSize: 14, color: 'blue' }}>{values.PN ? "PN: " + values.PN : ''}</div></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                {clientPhoto &&
                                                    <img src={clientPhoto} onError={() => { this.setClientPhoto('person.png') }} alt='-' style={{ width: 'auto', maxHeight: 310, margin: 'auto' }} />
                                                }
                                                {!clientPhoto &&
                                                    <i className="material-icons" style={{ fontSize: 200, color: '#ccc', margin: 'auto' }} >
                                                        account_box
                                            </i>
                                                }
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>Age:</Grid><Grid item xs={9}>{client.age} years old</Grid>
                                                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>Date of Birth:</Grid><Grid item xs={9}>{client.dateOfBirth}</Grid>
                                                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>Civil Status:</Grid><Grid item xs={9}>{client.civilStatus}</Grid>
                                                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>Gender:</Grid><Grid item xs={9}>{client.gender === 'M' ? 'Male' : 'Female'}</Grid>
                                                    <Grid item xs={3} style={{ fontWeight: 'bold' }}>Address:</Grid><Grid item xs={9}>{client.address}</Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={inlineStyles.tdLabel}>Type of Loan</TableCell>
                                            <TableCell>{values.LoanName}</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Status</TableCell>
                                            <TableCell>{values.status}</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Payment Term</TableCell>
                                            <TableCell>{values.paymentmethod === 'M' ? 'Monthly' : 'Lump sum'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={inlineStyles.tdLabel}>Applied Amount</TableCell>
                                            <TableCell>{numberFormat(values.AmountApplied, 2)} - {values.computation}</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Approved Amount</TableCell>
                                            <TableCell>{numberFormat(values.approvedAmount, 2)}</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Net Proceeds</TableCell>
                                            <TableCell>{numberFormat(values.netproceeds, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={inlineStyles.tdLabel}>Terms</TableCell>
                                            <TableCell>{values.Term} month(s)</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Interest</TableCell>
                                            <TableCell>{values.interest}%</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Monthly Installment</TableCell>
                                            <TableCell>{numberFormat(values.MonthlyInstallment, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={inlineStyles.tdLabel}>Date Applied</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap' }}>{values.dateApplied}</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Date Approved</TableCell>
                                            <TableCell>{values.dateApproved}</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Date of Maturity</TableCell>
                                            <TableCell>{values.MaturityDate}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={inlineStyles.tdLabel}>Loan Processor</TableCell>
                                            <TableCell>{values.processor}</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Approved By</TableCell>
                                            <TableCell>{values.approvedByName}</TableCell>
                                            <TableCell style={inlineStyles.tdLabel}>Released Date</TableCell>
                                            <TableCell>{values.DateDisbursed}</TableCell>
                                        </TableRow>
                                        {this.state.schema.length > 2 &&
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    {!this.state.editPNremarks &&
                                                        <Typography>PN Remarks: {this.state.schema[2].value}</Typography>
                                                    }
                                                    {this.state.editPNremarks &&
                                                        <TextField
                                                            multiline
                                                            rows="2"
                                                            variant="outlined"
                                                            margin="dense"
                                                            name="PN_remarks"
                                                            label="Promisory Note Remarks"
                                                            value={this.state.schema[2].value}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            onChange={this.handleTextfieldChange('PN_remarks')}
                                                            disabled={this.state.isDataLoading}
                                                        />
                                                    }
                                                </TableCell>
                                                <TableCell className='buttonsGrid' style={{ textAlign: 'center' }}>
                                                    {!this.state.editPNremarks &&
                                                        <Button onClick={() => this.setComponentState('editPNremarks', true)} style={inlineStyles.buttonOrange} variant='contained' size="small">
                                                            <Icon className="fas fa-edit" style={inlineStyles.smallIcon}></Icon>Change PN Remarks
                                        </Button>
                                                    }
                                                    {this.state.editPNremarks &&
                                                        <Button onClick={Global.handleSaveForm(this)} style={inlineStyles.button} color="primary" variant='contained' size="small">
                                                            <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save PN Remarks
                                        </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        }

                                    </TableBody>
                                </Table>

                            }

                            {
                                (loanStatus === 'processing') &&
                                <div style={{ marginBottom: 10, marginTop: 10, padding: 10, borderWidth: 1, borderColor: '#ecebeb', borderStyle: 'solid' }}>

                                    <Button onClick={() => this.submitForApproval()} color="primary" variant='contained' size="small" style={{ marginRight: 10 }}>
                                        Submit for Approval
                                    </Button>
                                    <Button onClick={() => this.cancelLoan()} style={inlineStyles.buttonOrange} variant='contained' size="small">
                                        Cancel Loan
                                    </Button>
                                </div>
                            }
                            {
                                (loanStatus === 'approval') &&
                                (
                                    this.state.isDialogDataLoading ?
                                        <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                            {this.props.parentComponent.loadingCircular()}
                                        </div>
                                        :
                                        <div style={{ marginBottom: 10, marginTop: 10, marginLeft: 10, padding: 10, borderWidth: 1, backgroundColor: '#fbfbef', borderColor: '#ecebeb', borderStyle: 'solid', textAlign: 'center' }}>
                                            <table border={0} style={{ marginBottom: 10 }} cellPadding={5}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ textAlign: 'left', verticalAlign: 'bottom' }}>Amount Approved:</th>
                                                        <th style={{ textAlign: 'left', verticalAlign: 'bottom' }}>Term:</th>
                                                        <th style={{ textAlign: 'left', verticalAlign: 'bottom' }}>Extension:</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="30%">
                                                            <TextField
                                                                variant="outlined"
                                                                margin='dense'
                                                                value={this.state.approvedAmount}
                                                                onChange={this.changeApprovalValue('approvedAmount')}
                                                            />
                                                        </td>
                                                        <td width="20%">

                                                            <TextField
                                                                variant="outlined"
                                                                margin='dense'
                                                                value={this.state.approvedTerm}
                                                                onChange={this.changeApprovalValue('approvedTerm')}
                                                            />

                                                        </td>
                                                        <td width="50%">
                                                            <TextField
                                                                select
                                                                variant="outlined"
                                                                margin='dense'
                                                                value={this.state.approvedExtendedTerm}
                                                                onChange={this.changeApprovalValue('approvedExtendedTerm')}
                                                            >
                                                                {terms.length > 0 && terms.map((row, itemindex) => {
                                                                    return (
                                                                        <MenuItem key={itemindex} value={row.key}>{row.value}</MenuItem>
                                                                    )
                                                                })
                                                                }
                                                            </TextField>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <TextField
                                                                multiline
                                                                rows="2"
                                                                variant="outlined"
                                                                value={this.state.approvedRemarks}
                                                                label="Approval Remarks"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={this.changeApprovalValue("approvedRemarks")}
                                                            >

                                                            </TextField>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <Button onClick={this.approveLoan} color="primary" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                Approve Loan
                                            </Button>
                                            <Button onClick={() => this.declineLoan()} color="secondary" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                Decline Loan
                                            </Button>
                                            <Button onClick={() => this.cancelLoan()} style={inlineStyles.buttonOrange} variant='contained' size="small">
                                                Cancel Loan
                                            </Button>
                                        </div>
                                )
                            }
                            {
                                (loanStatus === 'approved') &&
                                (
                                    this.state.isDialogDataLoading ?
                                        <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                            {this.props.parentComponent.loadingCircular()}
                                        </div>
                                        :
                                        <div style={{ marginBottom: 10, marginTop: 10, marginLeft: 10, padding: 10, borderWidth: 1, backgroundColor: '#fbfbef', borderColor: '#ecebeb', borderStyle: 'solid', textAlign: 'center' }}>
                                            <Button onClick={() => this.declineLoan()} color="secondary" variant='contained' size="small" style={{ marginRight: 10, marginLeft: 10 }}>
                                                Revoke Approval
                                            </Button>
                                            <Button onClick={() => this.cancelLoan()} style={inlineStyles.buttonOrange} variant='contained' size="small">
                                                Cancel Loan
                                            </Button>

                                            {PN ?
                                                <Fragment>
                                                    <Button onClick={() => this.setDialogAssignPNOpen(true)} color="primary" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                        Edit Promisory Note (PN)
                                            </Button>
                                                    <Button onClick={() => this.setCreateCVOpen(true)} variant='contained' size="small" style={inlineStyles.buttonGreen}>
                                                        Create CV
                                            </Button>
                                                </Fragment>
                                                :
                                                <Button onClick={() => this.setDialogAssignPNOpen(true)} color="primary" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                    Assign Promisory Note (PN)
                                            </Button>
                                            }

                                            <div style={{ margin: 'auto', padding: 10, lineHeight: '40px' }}>
                                                {this.state.isShowingPdf
                                                    ? <Typography color="secondary" style={{ padding: 3 }}> Opening pdf report ...</Typography>
                                                    :
                                                    <Fragment>
                                                        <Button onClick={() => this.disclosure()} color="default" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                            Disclosure Statement
                                                        </Button>
                                                        <Button onClick={() => this.promisoryNote()} color="default" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                            Promisory Note
                                                        </Button>
                                                        <Button onClick={() => this.computationSheet()} color="default" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                            Computation Sheet
                                                        </Button>
                                                        {productCode && productCode === 'PL' &&
                                                            <Fragment>
                                                                <Button onClick={() => this.RFPLmonitoring()} color="default" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                                    RFPL Monitoring
                                                                </Button>
                                                                <Button onClick={() => this.RFPLagreement()} color="default" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                                    RFPL Agreement
                                                                </Button>
                                                                <Button onClick={() => this.deedOfUndertaking()} color="default" variant='contained' size="small" style={{ marginRight: 10 }}>
                                                                    Deed of Undertaking
                                                                </Button>
                                                            </Fragment>
                                                        }
                                                    </Fragment>
                                                }
                                            </div>

                                        </div>
                                )
                            }
                            {
                                loanDetails.loanCanExtend > 0 &&
                                <div style={{ marginBottom: 10, marginTop: 10, borderWidth: 1, borderColor: '#ecebeb', borderStyle: 'solid' }}>
                                    <div style={Object.assign({}, inlineStyles.sectionHeaderBgColor, inlineStyles.sectionHeaderPads, inlineStyles.sectionHeaderSize)}>
                                        Loan Application
                                </div>
                                    <div style={{ padding: 10, textAlign: 'center' }}>
                                        {loanDetails.loanCanExtend === 2
                                            ?
                                            <Fragment>                                                
                                            <Button onClick={() => this.extendLoan()} color="secondary" variant='contained' size="small">
                                                    Extend Loan
                                            </Button>

                                            </Fragment>
                                            :
                                            <code>This Loan cannot be extended. If Loan Info is not correct, please contact Administrator.</code>
                                        }
                                    </div>
                                </div>

                            }


                        </Grid>

                        <Grid item xs={12} md={3} sm={4}>
                            {
                                this.state.isDataLoading ?
                                    <Fragment>
                                        <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                            {this.props.parentComponent.loadingCircular()}
                                        </div>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <div style={{ padding: 20, backgroundColor: statusColor, color: '#fff', textAlign: 'center', textTransform: 'uppercase' }}>
                                            Loan Status: {loanDetails.hasOwnProperty('loaninfo') ? loanDetails.loaninfo.status : ''}
                                        </div>
                                        {loanDetails.hasOwnProperty('aob') && loanDetails.aob > 0 &&
                                            <div style={{ padding: 20, marginTop: 10, marginBottom: 10, textAlign: 'center', backgroundColor: '#ff1744', color: '#fff', textTransform: 'uppercase', fontWeight: 'bold' }}>
                                                Total AOB: Php {loanDetails.hasOwnProperty('aob') ? numberFormat(loanDetails.aob, 2) : ''}
                                            </div>
                                        }
                                        <Typography style={Object.assign({}, inlineStyles.sectionHeaderSize, inlineStyles.sectionHeaderBgColor, inlineStyles.sectionHeaderPads)}>Loan Computation</Typography>
                                        <Table size="small" className="fees">
                                            <TableBody>
                                                <TableRow><TableCell style={{ fontWeight: 'bold' }}>Total Loans:</TableCell>
                                                    <TableCell align="right">{numberFormat(values.AmountApplied, 2)}</TableCell>
                                                </TableRow>
                                                <TableRow><TableCell style={{ fontWeight: 'bold' }}>Interest:</TableCell>
                                                    <TableCell align="right">{numberFormat(values.interest, 2)}%</TableCell>
                                                </TableRow>
                                                <TableRow><TableCell style={{ fontWeight: 'bold' }}>Principal:</TableCell>
                                                    <TableCell align="right">{numberFormat(values.principalAmount, 2)}</TableCell>
                                                </TableRow>
                                                <TableRow><TableCell style={{ fontWeight: 'bold', backgroundColor: '#f1f1f1' }} colSpan={2}><div style={{ fontSize: 14 }}>Fees:</div></TableCell>
                                                </TableRow>
                                                {fees && fees.length > 0 && fees.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontWeight: 'bold' }}>{row.feeName}:</TableCell>
                                                            <TableCell align="right">{numberFormat(row.value, 2)}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Total Fees:</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align='right'>{numberFormat(totalFees, 2)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold', color: 'red' }}><div style={{ fontSize: 14 }}>Net Proceeds:</div></TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', color: 'red' }} align='right'><div style={{ fontSize: 14 }}>{numberFormat(values.AmountApplied - totalFees, 2)}</div></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Fragment>
                            }
                        </Grid>

                    </Grid>


                </Paper>

                <Paper>

                    <Tabs defaultIndex={0}>
                        <TabList>
                            <Tab>Transactions</Tab>
                            <Tab>Ledger</Tab>
                            <Tab>Collateral</Tab>
                            <Tab>Requirements</Tab>
                            <Tab>Co-Maker</Tab>
                            <Tab>CI Report</Tab>
                            <Tab>Other Loans</Tab>
                            <Tab>Recommendations</Tab>
                        </TabList>
                        <TabPanel className="tab-transactions tabpanel">
                            <div style={{ minHeight: 100 }}>
                                <Table
                                    size='small'
                                    style={{ maxWidth: 700 }}
                                    align="center"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                Date
                                            </TableCell>
                                            <TableCell align="center">
                                                Trans Type
                                            </TableCell>
                                            <TableCell align="center">
                                                Reference No.
                                            </TableCell>
                                            <TableCell align="center">
                                                DR
                                            </TableCell>
                                            <TableCell align="center">
                                                CR
                                            </TableCell>
                                            <TableCell align="center">
                                                #
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions.length > 0 && transactions.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{row.date_transaction}</TableCell>
                                                    <TableCell align="center">{row.transType}</TableCell>
                                                    <TableCell align="center">{row.reference_no}</TableCell>
                                                    <TableCell align="center">{numberFormat(row.dr_total, 2)}</TableCell>
                                                    <TableCell align="center">{numberFormat(row.cr_total, 2)}</TableCell>
                                                    <TableCell align="center">-</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </TabPanel>
                        <TabPanel className="tab-ledger tabpanel">
                            <div style={{ minHeight: 100 }}>
                                <Table
                                    size='small'
                                    style={{ maxWidth: 700 }}
                                    align="center"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                #
                                            </TableCell>
                                            <TableCell align="center">
                                                Due Date
                                            </TableCell>
                                            <TableCell align="center">
                                                Amount Due
                                            </TableCell>
                                            <TableCell align="center">
                                                Amount Paid
                                            </TableCell>
                                            <TableCell align="center">
                                                Date Paid
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ledger.length > 0 && ledger.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center">{row.DueDate}</TableCell>
                                                    <TableCell align="center">{numberFormat(row.AmountDue, 2)}</TableCell>
                                                    <TableCell align="center">{row.Paid ? numberFormat(row.Paid, 2) : '0'}</TableCell>
                                                    <TableCell align="center">{row.DatePaid ? row.DatePaid : '-'}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>

                            </div>
                        </TabPanel>
                        <TabPanel className="Collateral tabpanel">
                            <div style={{ minHeight: 100 }}>
                                {!this.state.isDialogDataLoading && collaterals.length > 0 &&
                                    <Fragment>
                                        <Table
                                            size='small'
                                            style={{ maxWidth: 1000 }}
                                            align="center"
                                        >
                                            <TableBody>
                                                {collaterals.length > 0 && collaterals.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontWeight: 'bold', width: 200 }}>{row.collateralname} :</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    type='text'
                                                                    value={row.value}
                                                                    onChange={this.handleCollateralfieldChange(row.collateralname)}
                                                                    readOnly={true}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                        <div style={{ padding: 5, textAlign: 'center' }}>
                                            <Button onClick={this.saveCollateral} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                                                Save Collaterals
                                        </Button>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    this.state.isDialogDataLoading &&
                                    <div style={{ padding: 20, textAlign: 'center' }}>
                                        {this.props.parentComponent.loadingCircular()}
                                    </div>
                                }
                            </div>
                            <div style={{ border: '1px solid' }}>
                                <div style={{ padding: 20 }}>
                                    <Button onClick={() => this.setDialogUploadOpen(true)} style={inlineStyles.buttonOrange} variant='contained' size="small">
                                        <i className="fas fa-cloud-upload-alt"></i> <span> Upload Photos</span>
                                    </Button>
                                </div>
                                {
                                    !this.state.isDialogDataLoading &&
                                    <CollateralImages parentComponent={this} App={this.props.parentComponent} reloadPage={this.state.reloadPage} />
                                }
                            </div>
                        </TabPanel>
                        <TabPanel className="requirements tabpanel">
                            {submittedRequirements.length > 0 &&
                                <Table
                                    size='small'
                                    style={{ maxWidth: 700 }}
                                    align="center"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                Submitted
                                            </TableCell>
                                            <TableCell align="center">
                                                Requirements
                                            </TableCell>
                                            <TableCell align="center">
                                                Date Submitted
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {submittedRequirements.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            onClick={event => this.handleCheckboxClick(event, row.reqID)}
                                                            checked={this.isSelected(row.reqID)}
                                                            color="primary"
                                                            onChange={() => this.onSelectSetSubmitted(row.reqID)}
                                                            inputProps={{ 'aria-label': 'Set this as submitted' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.requirement}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.dateSubmitted ? row.dateSubmitted.split(' ')[0] : ''}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            }

                        </TabPanel>
                        <TabPanel className="Co-Maker tabpanel">
                            <div style={{ minHeight: 100 }}>
                                <Table
                                    style={{ maxWidth: 700 }}
                                    size='small'
                                    align="center"
                                >

                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                Co-Maker Name
                                            </TableCell>
                                            <TableCell align="center">Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {comaker.length > 0 && comaker.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="center">
                                                        {row.clientName}
                                                    </TableCell>
                                                    <TableCell width="50px" align="center" style={{ whiteSpace: 'nowrap' }}>
                                                        <IconButton style={inlineStyles.iconButton} onClick={() => this.confirmDeleteRow(row.comakerID, row.clientName, 'comaker')} aria-label="Delete row">
                                                            <Icon className={'fas fa-trash-alt fa-small'} color="error" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={2} align="center">
                                                <Button onClick={() => this.addNew('comaker')} style={inlineStyles.buttonOrange} variant='contained' size="small">
                                                    <i className="material-icons">person_add</i> <span>Add Co-Maker</span>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                        </TabPanel>
                        <TabPanel className="CIReport tabpanel">
                            <div style={{ minHeight: 100, padding: 10 }}>
                                {cireport.value &&
                                    <Fragment>
                                        <div align="right">
                                            <Button onClick={() => this.setOpenEditor(true, 'CI Report', cireport.value)} style={inlineStyles.buttonOrange} color="primary" variant='contained' size="small">
                                                <Icon className="fas fa-pencil-alt" style={inlineStyles.smallIcon}></Icon>Edit CI Report
                                    </Button>
                                        </div>
                                        <b>{cireport.ci_name}</b>:
                                    <br />
                                        <br />
                                        <div dangerouslySetInnerHTML={{ __html: cireport.value }} />

                                    </Fragment>
                                }
                                {!cireport.value &&
                                    <Button onClick={() => this.setOpenEditor(true, 'CI Report')} style={inlineStyles.buttonOrange} color="primary" variant='contained' size="small">
                                        <Icon className="fas fa-plus" style={inlineStyles.smallIcon}></Icon>Add CI Report
                                    </Button>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel className="OtherLoans tabpanel">
                            <div style={{ minHeight: 100 }}>
                                <OtherLoans parentComponent={this} App={this.props.parentComponent} reloadPage={this.state.reloadPage} />
                            </div>
                        </TabPanel>
                        <TabPanel className="Recommendations tabpanel">
                            <div style={{ minHeight: 100, padding: 15 }}>

                                {loanremarks.remarks &&
                                    <Fragment>
                                        <b>{loanremarks.name}</b>
                                        <br />
                                        {loanremarks.dateadd}
                                        <br />
                                        <div dangerouslySetInnerHTML={{ __html: loanremarks.remarks }} />
                                    </Fragment>
                                }
                                {!loanremarks.remarks &&
                                    <Fragment>No Loan remarks Found!</Fragment>
                                }

                            </div>
                        </TabPanel>

                    </Tabs>

                </Paper>
                <Dialog
                    open={this.state.deleteDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description"></DialogContentText>
                        <div style={{ padding: 15 }}>
                            Do you want to continue deleting "{objName}"?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setDeleteDialogOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            No
                    </Button>
                        <Button onClick={() => this.confirmDeletion(this.state.id)} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                            Yes
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogAddEditOpen}
                    aria-labelledby="alert-dialog2-title"
                    aria-describedby="alert-dialog2-description"
                    fullScreen
                >
                    <DialogTitle id="alert-dialog2-title">{this.state.editMode ? "Edit " + objName : "Add a " + objName}</DialogTitle>
                    <DialogContent dividers>
                        <Fragment>
                            {
                                this.state.dialogNotification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 5 }}>
                                    {this.state.dialogNotification}
                                </Typography>
                            }
                            {
                                this.state.isDialogDataLoading &&
                                <div style={{ padding: 20, minWidth: 350, textAlign: 'center' }}>
                                    {this.props.parentComponent.loadingCircular()}
                                </div>
                            }
                            {!this.state.isDialogDataLoading && !this.state.openDraftEditor && this.state.editFormSchema.length &&
                                <Fragment>
                                    <Tabs defaultIndex={0}>
                                        <TabList>
                                            <Tab>Select from Client list</Tab>
                                            <Tab>Add New Client Profile</Tab>
                                        </TabList>
                                        <TabPanel className="tabpanel">
                                            <TextAutoSearch parentComponent={this.props.parentComponent} tabname='client' t={this} />
                                            <div style={{ padding: 5, textAlign: 'center' }}>
                                                <Button onClick={this.saveCoMaker} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                                                    Save
                                            </Button>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className="tabpanel">
                                            <EditClients parentComponent={this.props.parentComponent} theme={this.props.theme} saveClientDialogForm={this.saveClientDialogForm} />
                                        </TabPanel>
                                    </Tabs>
                                </Fragment>
                            }
                            {!this.state.isDialogDataLoading && this.state.openDraftEditor &&
                                <DraftEditor editorContent={editorContent} parentComponent={this}
                                    saveContent={this.saveCIReport}
                                />
                            }
                        </Fragment>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setEditDialogOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            Cancel
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogUploadOpen}
                    aria-labelledby="alert-dialog2-title"
                    aria-describedby="alert-dialog2-description"
                    fullScreen
                >
                    <DialogTitle id="alert-dialog2-title">Upload Collateral Photos</DialogTitle>
                    <DialogContent dividers>
                        <Fragment>
                            {
                                this.state.dialogNotification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 5 }}>
                                    {this.state.dialogNotification}
                                </Typography>
                            }
                            {
                                this.state.isDialogDataLoading &&
                                <div style={{ padding: 20, minWidth: 350, textAlign: 'center' }}>
                                    {this.props.parentComponent.loadingCircular()}
                                </div>
                            }
                            {!this.state.isDialogDataLoading &&
                                <Fragment>
                                    <FileUploader App={this.props.parentComponent} parentComponent={this} />

                                </Fragment>
                            }

                        </Fragment>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setDialogUploadOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            Close
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogAssignPNOpen}
                    aria-labelledby="alert-dialog2-title"
                    aria-describedby="alert-dialog2-description"
                >
                    <DialogTitle id="alert-dialog2-title">Assign PN</DialogTitle>
                    <DialogContent dividers>
                        <Fragment>
                            {
                                this.state.dialogNotification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 5 }}>
                                    {this.state.dialogNotification}
                                </Typography>
                            }
                            {
                                this.state.isDialogDataLoading &&
                                <div style={{ padding: 20, minWidth: 350, textAlign: 'center' }}>
                                    {this.props.parentComponent.loadingCircular()}
                                </div>
                            }
                            {!this.state.isDialogDataLoading &&
                                <Fragment>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        placeholder="Enter PN here"
                                        autoFocus={true}
                                        value={this.state.assignedPN}
                                        onChange={(e) => this.setComponentState('assignedPN', e.target.value)}
                                    />
                                </Fragment>
                            }

                        </Fragment>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.assignPN} variant='contained' color="primary" size="small" style={inlineStyles.button}>
                            Assign PN
                        </Button>
                        <Button onClick={() => this.setDialogAssignPNOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogCreateCVOpen}
                    aria-labelledby="alert-dialog2-title"
                    aria-describedby="alert-dialog2-description"
                >
                    <DialogTitle id="alert-dialog2-title">Create Check Voucher</DialogTitle>
                    <DialogContent dividers>
                        <Fragment>
                            {
                                this.state.dialogNotification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 5 }}>
                                    {this.state.dialogNotification}
                                </Typography>
                            }
                            {
                                this.state.isDialogDataLoading &&
                                <div style={{ padding: 20, minWidth: 350, textAlign: 'center' }}>
                                    {this.props.parentComponent.loadingCircular()}
                                </div>
                            }
                            {!this.state.isDialogDataLoading &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label="Payee"
                                            required={true}
                                            value={this.state.checkVoucher.payee}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(e) => this.setCVState('payee', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            error={this.state.isDialogDataError}
                                            label={this.state.isDialogDataError ? "CV No. is required" : "CV No."}
                                            variant="outlined"
                                            margin="dense"
                                            autoFocus={true}
                                            required={true}
                                            value={this.state.checkVoucher.cvno}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(e) => this.setCVState('cvno', e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label="PN No."
                                            value={PN}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            select
                                            error={this.state.isDialogDataError}
                                            label={this.state.isDialogDataError ? 'Bank is required' : "Bank"}
                                            variant="outlined"
                                            value={this.state.checkVoucher.bank}
                                            required={true}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(e) => this.setCVState('bank', e.target.value)}
                                        >
                                            {banks.length > 0 && banks.map((row, index) => (
                                                <MenuItem key={row.branchBankID} value={row.branchBankID}>
                                                    {row.bankCode}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            error={this.state.isDialogDataError}
                                            label={this.state.isDialogDataError ? "Check No. is required" : "Check No."}
                                            required={true}
                                            value={this.state.checkVoucher.checkno}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(e) => this.setCVState('checkno', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label="Amount"
                                            value={numberFormat(netproceeds, 2)}
                                            disabled={true}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            multiline={true}
                                            rows={2}
                                            variant="outlined"
                                            margin="dense"
                                            label="Explanation"
                                            value={this.state.checkVoucher.explanation}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(e) => this.setCVState('explanation', e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <h6 style={{ textAlign: 'center', color: 'red' }}>Double Check the Computation before you proceed.</h6>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <table border={1} cellPadding={2} align="center">
                                            <tr>
                                                <th className="text-center">Account/Description</th>
                                                <th className="text-center">DR</th>
                                                <th className="text-center">CR</th>
                                            </tr>
                                            {feeNameByGL && feeNameByGL.length > 0 && feeNameByGL.map((row, index) => {
                                                glAccount = row.gl_account;
                                                return (
                                                    <tr>
                                                        <td>{row.fee_name}</td>
                                                        <td align="right">{numberFormat(AmountApplied, 2)}</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                )
                                            })}
                                            {fees && fees.length > 0 && fees.map((row, index) => {
                                                var feeValue = parseFloat(row.value);
                                                totalCVFees = totalCVFees + feeValue;
                                                if (feeValue > 0) {
                                                    return (
                                                        <tr>
                                                            <td>{row.feeName}</td>
                                                            <td>&nbsp;</td>
                                                            <td align="right">{numberFormat(feeValue, 2)}</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return false;
                                                }
                                            })}
                                            <tr className="trBottomBorder">
                                                <td>Cash in Bank</td>
                                                <td>&nbsp;</td>
                                                <td align="right">{numberFormat(AmountApplied - totalCVFees, 2)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td align="right">{numberFormat(AmountApplied)}</td>
                                                <td align="right">{numberFormat(netproceeds + totalCVFees)}</td>
                                            </tr>
                                        </table>

                                    </Grid>

                                </Grid>
                            }

                        </Fragment>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.createCV(netproceeds, totalCVFees, glAccount)} variant='contained' color="primary" size="small" style={inlineStyles.button}>
                            Create
                        </Button>
                        <Button onClick={() => this.setCreateCVOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}

