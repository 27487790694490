import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Button, Icon } from '@material-ui/core';
import { inlineStyles } from '../utils/global';

class DraftEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }
  uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        let user = JSON.parse(sessionStorage.getItem('userdata'));
        if (typeof (user.token) === 'undefined' && !user.token) reject('Logged out');
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://cas.ycfcserver.com/proc/common/uploadImage.php');
        //xhr.setRequestHeader('Authorization', user.token);
        const data = new FormData();
        data.append('image', file);
        data.append('token', user.token);
        data.append('user_id', user.userdata[0].id);
        data.append('colId', this.props.parentComponent.state.loanDetails.pensionID);
        xhr.send(data);
        xhr.addEventListener('load', () => {          
          const response = JSON.parse(xhr.responseText);
          if (!response.data.success) {
            this.props.parentComponent.openSnackBar(`Error occurred: ${response.data.status} `, 'error', true);
          }
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      }
    );
  }
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    
  };
  saveEditorContent = () => {    
    let htmlValue = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));        
    this.props.saveContent(htmlValue);
  };
  componentDidMount() {
    let value = this.props.editorContent;
    if (typeof value==='string') {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState: editorState });
    }
  }

  render() {
    const { editorState } = this.state;
    return (
      <div style={{position: 'relative', height: '100%'}}>        
      <Editor
        editorState={editorState}
        wrapperClassName="draft-wrapper"
        editorClassName="draft-editor"
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: this.uploadImageCallBack,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
        }}
      />      
      <Button onClick={this.saveEditorContent} style={inlineStyles.button} color="primary" variant='contained' size="small">
          <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save
      </Button>
      </div>
    )
  }
}

export default DraftEditor;