import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../utils/SnackerBar';
import { Global, inlineStyles, TabContainer } from '../../utils/global';
import { MenuItem, Grid } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import 'date-fns';
import '../../styles.css';
import JournalEntry from './JournalEntry';
import GenJournalList from './GenJournalList';

export default class GeneralJournal extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            id: props.parentComponent.state.clientID ? props.parentComponent.state.clientID : 0,
            referer: 'clients',
            notification: '',
            isLoading: true,
            isDataLoading: false,
            clientPhoto: null,
            selectedID: 0,
            branchId: 0,
            objName: '',
            schema: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            selectedTabIndex: 0,
            url: props.parentComponent.state.API_HOST+process.env.REACT_APP_CLIENTS_URL,
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
        return;
    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }

    setBranch = (v) => {
        var values = { ...this.state.schema }
        values.branchId = v.target.value;
        this.setState({ values });
    }

    handleCancel = () => {
        this.props.parentComponent.selectMenu('clients');
    }

    handleTabChange = (event, newVal) => {
        if (typeof newVal == 'number') {
            this.handleChangeTabIndex(newVal);
        }
    }
    handleChangeTabIndex = (newVal) => {
        this.setState({ selectedTabIndex: newVal });
    }
    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            if (this.props.parentComponent.state.snackBarMsg.msg) {
                var propSnackBar = this.props.parentComponent.state.snackBarMsg;
                this.openSnackBar(propSnackBar.msg, propSnackBar.type, true);
                this.props.parentComponent.setState({ snackBarMsg: { msg: '', type: '' } });
            }            
            Global.getSchema(this, true); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */            
        }
    }    

    render() {
        var values = this.state.schema;
        var menuItems;        
        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.selectedTabIndex}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="" icon={<div className="tabIcon">General Journal</div>} />
                            <Tab label="" icon={<div className="tabIcon">Journal Entry</div>} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={this.props.theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.state.selectedTabIndex}
                        onChangeIndex={this.handleChangeTabIndex}
                    >
                        <TabContainer dir={this.props.theme.direction}>
                            {this.state.selectedTabIndex===0 &&
                            <Fragment>
                            {
                                this.state.notification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                                    {this.state.notification}
                                </Typography>
                            }
                            {
                                this.state.isDataLoading &&
                                <Fragment>
                                    {this.props.parentComponent.loadingCircular()}
                                </Fragment>
                            }
                            {
                                !this.state.isDataLoading &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>         
                                        <GenJournalList parentComponent={this.props.parentComponent} branchId={this.props.parentComponent.state.branchId} Journal={this} Global={Global} />
                                    </Grid>
                                </Grid>
                            }
                            </Fragment>
                            }
                        </TabContainer>
                        <TabContainer dir={this.props.theme.direction}>
                            {this.state.selectedTabIndex===1 &&
                            <Fragment>
                            {
                                this.state.notification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                                    {this.state.notification}
                                </Typography>
                            }
                            {
                                this.state.isDataLoading &&
                                <Fragment>
                                    {this.props.parentComponent.loadingCircular()}
                                </Fragment>
                            }
                            {
                                !this.state.isDataLoading &&
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <JournalEntry parentComponent={this.props.parentComponent} branchId={this.props.parentComponent.state.branchId} GeneralJournal={this} Global={Global} />
                                    </Grid>
                                </Grid>
                            }
                            </Fragment>
                            }
                        </TabContainer>
                    </SwipeableViews>

                </Paper>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}