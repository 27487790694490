import React, { useState, useEffect, Fragment } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Link, Table, TableRow, TableCell, TableBody, IconButton, Icon, TableHead } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { inlineStyles } from '../utils/global';

const useWelcomeStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        paddingTop: 20,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '100%',
        minHeight: 100,
        padding: 15,
        margin: 0,
        position: 'relative',
    },
    paperHasHover: {
        '&:hover': {
            backgroundColor: '#e6f9fd'
        }
    },
    hasPointer: {
        cursor: 'pointer',
    },

    typo1: {
        color: '#4bbbce',
        fontSize: 24,
        zIndex: 10,
        position: 'relative',
        textShadow: '1px 1px #fff',
    },
    typo2: {
        color: '#8862e0',
        fontSize: 24,
        zIndex: 10,
        position: 'relative',
        textShadow: '1px 1px #fff',
    },
    typo3: {
        color: '#2f8ee0',
        fontSize: 24,
        zIndex: 10,
        position: 'relative',
        textShadow: '1px 1px #fff',
    },
    typo4: {
        color: '#f24734',
        fontSize: 24,
        zIndex: 10,
        position: 'relative',
        textShadow: '1px 1px #fff',
    },
    icon1: {
        color: '#4bbbce',
    },
    icon2: {
        color: '#8862e0',
        textShadow: ''
    },
    icon3: {
        color: '#2f8ee0',
    },
    icon4: {
        color: '#f24734',
    },
    icon_color1: {
        color: '#b6e0aa',
    },
    icon_onpaper: {
        fontSize: 55,
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 1,
    },
    papertitle: {
        color: '#333',
        fontSize: 16,
    },
    subtitle: {
        color: '#333',
        fontSize: 14,
        zIndex: 10,
        position: 'relative',
    },
    bottomlink: {
        position: 'absolute',
        right: 10,
        bottom: 5,
        fontSize: 12,
        color: '#2196f3',
    }
}));
function showProcessing(props) {
    props.parentComponent.selectMenu('loans.processing');
}
function showForApproval(props) {
    props.parentComponent.selectMenu('loans.forApproval');
}
function showApproved(props) {
    props.parentComponent.selectMenu('loans.approved');
}
function showGranted(props) {
    props.parentComponent.selectMenu('loans.granted');
}
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function showClient(props, id) {
    props.parentComponent.selectMenu('clients.details', id, {});
}
const chartOptions = {
    redraw: true,
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return Number(tooltipItem.yLabel).toLocaleString('en')
            }
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                callback(value) {
                    return Number(value).toLocaleString('en')
                }
            }
        }]
    }
}

function showDetails(props, row) {
    var oStates = props.parentComponent.state;
    var sMenuitem = '';
    var Id = 0;
    if (props.parentComponent.state.searchTable === 'Client') {
        sMenuitem = 'clients.details';        
        Id = { idFor: 'clientID', value: row.id };
    } else {
        sMenuitem = 'clients.loandetails';
        oStates.clientID = row.ClientID;
        props.parentComponent.setState({ oStates });
        Id = { idFor: 'loanid', value: row.id };
    }
    props.parentComponent.selectMenu(sMenuitem, Id);
}

function Welcome(props) {
    const defaultData = {
        labels: [],
        datasets: [
            {
                label: 'Collections',
                backgroundColor: '#bbdefb',
                borderColor: '#64b5f6',
                borderWidth: 1,
                hoverBackgroundColor: '#90caf9',
                hoverBorderColor: '#64b5f6',
                data: []
            },
            {
                label: 'Disbursements',
                backgroundColor: '#ffcdd2',
                borderColor: '#e57373',
                borderWidth: 1,
                hoverBackgroundColor: '#ef9a9a',
                hoverBorderColor: '#ef5350',
                data: []
            },
            {
                label: 'Adjustments',
                backgroundColor: '#ffe0b2',
                borderColor: '#ffb74d',
                borderWidth: 1,
                hoverBackgroundColor: '#ffcc80',
                hoverBorderColor: '#ffa726',
                data: []
            },

            {
                label: 'Recap',
                backgroundColor: '#b2ebf2',
                borderColor: '#4dd0e1',
                borderWidth: 1,
                hoverBackgroundColor: '#80deea',
                hoverBorderColor: '#26c6da',
                data: []
            }
        ]
    };
    const [chartData, setChartData] = useState(defaultData);
    const [isLoaded, setPageLoaded] = useState(false);
    const classes = useWelcomeStyles();
    const permissions = props.parentComponent.state.permissions;
    // eslint-disable-next-line
    useEffect(() => {
        var data = chartData;
        var setData = false;
        if ((!isLoaded && props.parentComponent.state.monthlyCollections.hasOwnProperty('rows') && props.parentComponent.state.monthlyCollections.rows.length > 0) || props.parentComponent.state.monthlyCollections.loaded) {
            data.datasets[0].data = [];
            data.labels = [];
            props.parentComponent.state.monthlyCollections.rows.forEach((value, index) => {
                data.labels.push(monthNames[index]);
                data.datasets[0].data.push(parseFloat(value.amt));
            })
            props.parentComponent.incrementLoadedCount();
            var monthlyCollections = props.parentComponent.state.monthlyCollections;
            monthlyCollections.loaded = false;
            props.parentComponent.setState({ monthlyCollections });
            setData = true;
        }
        if ((!isLoaded && props.parentComponent.state.monthlyDisbursements.hasOwnProperty('rows') && props.parentComponent.state.monthlyDisbursements.rows.length > 0) || props.parentComponent.state.monthlyDisbursements.loaded) {
            data.datasets[1].data = [];
            props.parentComponent.state.monthlyDisbursements.rows.forEach((value) => {
                data.datasets[1].data.push(parseFloat(value.amt));
            })
            props.parentComponent.incrementLoadedCount();
            var monthlyDisbursements = props.parentComponent.state.monthlyDisbursements;
            monthlyDisbursements.loaded = false;
            props.parentComponent.setState({ monthlyDisbursements });
            setData = true;
        }
        if ((!isLoaded && props.parentComponent.state.monthlyAdjustments.hasOwnProperty('rows') && props.parentComponent.state.monthlyAdjustments.rows.length > 0) || props.parentComponent.state.monthlyAdjustments.loaded) {
            data.datasets[2].data = [];
            props.parentComponent.state.monthlyAdjustments.rows.forEach((value) => {
                data.datasets[2].data.push(parseFloat(value.amt));
            })
            props.parentComponent.incrementLoadedCount();
            var monthlyAdjustments = props.parentComponent.state.monthlyAdjustments;
            monthlyAdjustments.loaded = false;
            props.parentComponent.setState({ monthlyAdjustments });
            setData = true;
        }
        if ((!isLoaded && props.parentComponent.state.monthlyRecap.hasOwnProperty('rows') && props.parentComponent.state.monthlyRecap.rows.length > 0) || props.parentComponent.state.monthlyRecap.loaded) {
            data.datasets[3].data = [];
            props.parentComponent.state.monthlyRecap.rows.forEach((value) => {
                data.datasets[3].data.push(parseFloat(value.amt));
            })
            props.parentComponent.incrementLoadedCount();
            var monthlyRecap = props.parentComponent.state.monthlyRecap;
            monthlyRecap.loaded = false;
            props.parentComponent.setState({ monthlyRecap });
            setData = true;
        }
        if (setData) {
            setChartData(data);
        }
        if (!isLoaded) {
            setPageLoaded(true);
        }
    });
    var header = [];
    header.push({ name: '#' });
    if (props.parentComponent.state.searchTable === 'Client') {
        header.push({ name: 'Client Name' });
        header.push({ name: 'Address' });
    } else {
        header.push({ name: 'PN' });
        header.push({ name: 'Client Name' });
    }
    header.push({ name: 'View' });
    return (
        <React.Fragment>
            {props.parentComponent.state.searchStarted &&
                <Paper style={{ padding: 20, minHeight: 400 }}>
                    {
                        props.parentComponent.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {props.parentComponent.state.notification}
                        </Typography>
                    }
                    {
                        props.parentComponent.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                {props.parentComponent.loadingCircular()}
                            </div>
                        </Fragment>
                    }
                    {
                        !props.parentComponent.state.isDataLoading && !props.parentComponent.state.searchResult.length &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <Typography variant='h6'>No Result</Typography>
                            </div>
                        </Fragment>
                    }
                    {
                        !props.parentComponent.state.isDataLoading && props.parentComponent.state.searchResult.length > 0 && props.parentComponent.state.searchResult &&
                        <Fragment>
                            <Typography variant="h6" align="center">Search Result</Typography>
                            <Table
                                style={{ width: 400 }}
                                aria-labelledby="tableTitle"
                                size='medium'
                                align='center'
                            >
                                <TableHead>
                                    <TableRow>
                                        {header.map((row, index) => {
                                            return <TableCell key={index}>{row.name}</TableCell>
                                        })}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.parentComponent.state.searchResult.map((row, index) => {
                                        const labelId = `table-row-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                key={index}
                                            >
                                                <TableCell style={{ width: 20 }} className="listRowNum" id={labelId} scope="row" padding="none" align="center">
                                                    {index + 1}
                                                </TableCell>
                                                {props.parentComponent.state.searchTable === 'Client' &&
                                                    <Fragment>
                                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                            <Link to="#" onClick={() => showDetails(props, row)} aria-label="Show Details">{row.firstName} {row.LastName}</Link>
                                                        </TableCell>
                                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                            {row.address}
                                                        </TableCell>
                                                    </Fragment>
                                                }
                                                {props.parentComponent.state.searchTable === 'PN' &&
                                                    <Fragment>
                                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                            <Link to="#" onClick={() => showDetails(props, row)} aria-label="Show Details">{row.PN}</Link>
                                                        </TableCell>
                                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                            {row.clientname}
                                                        </TableCell>
                                                    </Fragment>
                                                }

                                                <TableCell align="center" style={{ whiteSpace: 'nowrap', width: 50, padding: 5 }}>
                                                    <IconButton style={inlineStyles.iconButton} onClick={() => showDetails(props, row)} aria-label="Show Details">
                                                        <Icon className={'fas fa-info-circle fa-small '} color="primary" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    }

                                </TableBody>
                            </Table>

                        </Fragment>
                    }

                </Paper>
            }
            {!props.parentComponent.state.searchStarted &&
                <Grid container spacing={2}>
                    {((permissions.hasOwnProperty('LoanProcessing') && permissions.LoanProcessing.indexOf('f') !== -1) || permissions.LoanProcessing.indexOf('v') !== -1)
                        &&
                        <Grid item xs={12} md={3}>
                            <Paper className={clsx(classes.paper, classes.hasPointer, classes.paperHasHover)} onClick={() => showProcessing(props)}>
                                <i className={clsx(classes.icon1, 'material-icons', classes.icon_onpaper)}>donut_large</i>
                                <Typography className={classes.typo1}>
                                    {props.parentComponent.state.loanProcessingCount.rows}
                                </Typography>
                                <Typography className={classes.subtitle}>
                                    Processing
                            </Typography>
                                <Typography className={classes.bottomlink}>
                                    Click here
                            </Typography>
                            </Paper>
                        </Grid>
                    }
                    {((permissions.hasOwnProperty('LoanApproval') && permissions.LoanApproval.indexOf('f') !== -1) || permissions.LoanApproval.indexOf('v') !== -1)
                        &&
                        <Grid item xs={12} md={3}>
                            <Paper className={clsx(classes.paper, classes.hasPointer, classes.paperHasHover)} onClick={() => showForApproval(props)}>
                                <i className={clsx(classes.icon2, 'material-icons', classes.icon_onpaper)}>thumbs_up_down</i>
                                <Typography className={classes.typo2}>
                                    {props.parentComponent.state.loanForApprovalCount.rows}
                                </Typography>
                                <Typography className={classes.subtitle}>
                                    For Approval
                            </Typography>
                                <Typography className={classes.bottomlink}>
                                    Click here
                            </Typography>
                            </Paper>
                        </Grid>
                    }
                    {((permissions.hasOwnProperty('LoanApproved') && permissions.LoanApproved.indexOf('f') !== -1) || permissions.LoanApproved.indexOf('v') !== -1)
                        &&
                        <Grid item xs={12} md={3}>
                            <Paper className={clsx(classes.paper, classes.hasPointer, classes.paperHasHover)} onClick={() => showApproved(props)}>
                                <i className={clsx(classes.icon3, 'material-icons', classes.icon_onpaper)}>check_circle</i>
                                <Typography className={classes.typo3}>
                                    {props.parentComponent.state.loanApprovedCount.rows}
                                </Typography>
                                <Typography className={classes.subtitle}>
                                    Approved
                            </Typography>
                                <Typography className={classes.bottomlink}>
                                    Click here
                            </Typography>
                            </Paper>
                        </Grid>
                    }
                    {((permissions.hasOwnProperty('LoanGranted') && permissions.LoanGranted.indexOf('f') !== -1) || permissions.LoanGranted.indexOf('v') !== -1)
                        &&
                        <Grid item xs={12} md={3}>
                            <Paper className={clsx(classes.paper, classes.hasPointer, classes.paperHasHover)} onClick={() => showGranted(props)}>
                                <i className={clsx(classes.icon4, 'material-icons', classes.icon_onpaper)}>monetization_on</i>
                                <Typography className={classes.typo4}>
                                    {props.parentComponent.state.loanGrantedCount.rows}
                                </Typography>
                                <Typography className={classes.subtitle}>
                                    Granted
                            </Typography>
                                <Typography className={classes.bottomlink}>
                                    Click here
                            </Typography>
                            </Paper>
                        </Grid>
                    }
                    {((permissions.hasOwnProperty('Clients') && permissions.Clients.indexOf('f') !== -1) || permissions.Clients.indexOf('v') !== -1)
                        &&
                        <Grid item xs={12} md={3}>
                            <Paper className={classes.paper} style={{ height: 450 }}>
                                <i className={clsx(classes.icon_color1, 'material-icons', classes.icon_onpaper)}>people</i>
                                <Typography className={classes.papertitle}>
                                    Latest Clients
                            </Typography>
                                <List className={classes.root}>
                                    {props.parentComponent.state.latestClients && props.parentComponent.state.latestClients.length > 0 && props.parentComponent.state.latestClients.map((row, index) => {
                                        var clientPhoto = process.env.REACT_APP_IMAGE_DOMAIN + row.image;
                                        return (
                                            <ListItem key={index} style={{ cursor: 'pointer' }} onClick={() => showClient(props, row.id)}>
                                                <ListItemAvatar>
                                                    <Avatar style={{ position: 'relative' }}>
                                                        {row.image &&
                                                            <img src={clientPhoto} onError={(e) => { e.target.onerror = null; e.target.src = 'person.png' }} width="100%" alt='-' />
                                                        }
                                                        {!row.image &&
                                                            <i className="material-icons" >
                                                                account_box
                                                </i>
                                                        }
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={row.firstName + ' ' + row.LastName} secondary={row.branchname + ' ' + row.dateAdded.split(' ')[0]} />
                                            </ListItem>
                                        )
                                    })
                                    }
                                </List>
                                <Typography>
                                    <Link onClick={() => props.parentComponent.selectMenu('clients')} className={classes.bottomlink}>
                                        See All
                            </Link>
                                </Typography>
                            </Paper>
                        </Grid>
                    }
                    {((permissions.hasOwnProperty('DashboardGraphs') && permissions.DashboardGraphs.indexOf('f')) !== -1 || permissions.DashboardGraphs.indexOf('v') !== -1)
                        &&
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.paper} style={{ height: 450 }}>
                                {props.parentComponent.state.loadedCount > 3 &&
                                    <Bar
                                        data={chartData}
                                        options={chartOptions}
                                    />
                                }
                            </Paper>
                        </Grid>
                    }

                    {((permissions.hasOwnProperty('Settings') && permissions.Settings.indexOf('f') !== -1) || permissions.Settings.indexOf('v') !== -1)
                        &&
                        <Grid item xs={12} md={3}>
                            <Paper className={classes.paper} style={{ height: 450 }}>
                                <i className={clsx(classes.icon3, 'material-icons', classes.icon_onpaper)}>people</i>
                                <Typography className={classes.papertitle}>
                                    Active Users
                            </Typography>
                                <div style={{ overflow: 'auto', height: 360, marginTop: 25 }}>
                                    <List className={classes.root} style={{ paddingTop: 0 }}>
                                        {props.parentComponent.state.activeUsers && props.parentComponent.state.activeUsers.length > 0 && props.parentComponent.state.activeUsers.map((row, index) => {
                                            return (
                                                <ListItem key={index} style={{ cursor: 'pointer', paddingTop: 0, paddingBottom: 6 }} onClick={() => showClient(props, row.id)}>
                                                    <ListItemText primary={row.firstname + ' ' + row.lastname + ' - ' + (row.branchcode ? row.branchcode : 'All Branches')} secondary={'Last login: ' + row.last_login} />
                                                </ListItem>
                                            )
                                        })
                                        }
                                    </List>
                                    <Typography>
                                        <Link onClick={() => props.parentComponent.selectMenu('settings.users')} className={classes.bottomlink}>
                                            See All
                            </Link>
                                    </Typography>
                                </div>
                            </Paper>
                        </Grid>
                    }
                </Grid>

            }
            <Typography style={{ position: 'fixed', bottom: 5, right: 10, fontSize: 10 }}>
                {process.env.REACT_APP_COMPANY} - Alpha Version
                </Typography>
        </React.Fragment >
    )
}

export default Welcome;