import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../utils/SnackerBar';
import { Global, inlineStyles } from '../../utils/global';
import { EnhancedTableToolbar, EnhancedTableHead } from '../../utils/common_page_with_table';
import { Box, Grid, Table, TableBody, TableRow, TableCell, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, MenuItem, RadioGroup, Radio, FormControlLabel, Checkbox } from '@material-ui/core';

import 'date-fns';
import '../../styles.css';
import { numberFormat } from '../../utils/common_utils';

const url = 'gl/journalEntry.php';

export default class JournalEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            branchId: props.parentComponent.state.branchId,
            addNew: true,
            deleteDialogOpen: false,
            notification: '',
            isLoading: true,
            isDataLoading: false,
            selectedID: 0,
            objName: '',
            schema: [],
            page: 0,
            pageTotal: 0,
            masterRow: {
                trandate: { error: '', value: '', label:'Date', required: true },
                jvno: { error: '', value: '', label:'JV No.', required: true },
                dcmcCode: { error: '', value: '', label:'DCMC Code', required: true },
                adjust: { error: '', value: false, label:'Adjust Client PN Ledger?', required: false },
                pn: { error: '', value: '', isdisabled: true,label:'PN', required: false },
                drcr: { error: '', value: 'dr', isdisabled: true,label:'Debit or Credit', required: false },
                exp: { error: '', value: '', isdisabled: true, label:'Explanation', required: false },
                amount: { error: '', value: '', isdisabled: true, label:'Amount', required: false },
                particulars: { error: '', value: '', label:'Particulars', required: true },
            },
            detailRows: [],
            errors: [],
            newRow: { account: { value: '', isdisabled: false }, dr: { value: 0, isdisabled: false }, cr: { value: 0, isdisabled: false } },
            noList: true,
            disableSave: true,
            searchFor: '',
            selected: [],
            url: props.parentComponent.state.API_HOST + url,
        };
    }
    
    handleTextfieldChange = (name) => event => {
        if (name === 'amount' && isNaN(event.target.value) === true) return;
        var rows = this.state.masterRow;
        rows[name].value = event.target.value;
        this.setState({ rows });
    };

    handleAddRowChange = (name) => event => {
        if ((name === 'dr' || name === 'cr') && isNaN(event.target.value) === true) return;
        var rows = this.state.newRow;
        if (name === 'dr') {
            rows.dr.isdisabled = false;
            if (event.target.value.length > 0) {
                rows.cr.isdisabled = true;
                rows.cr.value = 0;
            } else {
                rows.cr.isdisabled = false;
            }
        } else if (name === 'cr') {
            rows.cr.isdisabled = false;
            if (event.target.value.length > 0) {
                rows.dr.isdisabled = true;
                rows.dr.value = 0;
            } else {
                rows.dr.isdisabled = false;
            }
        }
        rows[name].value = event.target.value;
        this.setState({ rows });
    };
    handleToggle = (name) => event => {
        var rows = this.state.masterRow;
        rows[name].value = event.target.value;
        this.setState({ rows });
    };
    handleChecked = (name) => event => {
        if (name === 'adjust') {
            var rows = this.state.masterRow;
            if (event.target.checked) {
                rows.pn.isdisabled = false;
                rows.drcr.isdisabled = false;
                rows.exp.isdisabled = false;
                rows.amount.isdisabled = false;
            } else {
                rows.pn.isdisabled = true;
                rows.drcr.isdisabled = true;
                rows.exp.isdisabled = true;
                rows.amount.isdisabled = true;
            }
            this.setState({ rows });
        }
        var rows = this.state.masterRow;
        rows[name].value = event.target.checked;
        this.setState({ rows });
    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    initializeForm = () => {
        var masterRow = {
            trandate: { error: '', value: '', label:'Date', required: true },
            jvno: { error: '', value: '', label:'JV No.', required: true },
            dcmcCode: { error: '', value: '', label:'DCMC Code', required: true },
            adjust: { error: '', value: false, label:'Adjust Client PN Ledger?', required: false },
            pn: { error: '', value: '', isdisabled: true,label:'PN', required: false },
            drcr: { error: '', value: 'dr', isdisabled: true,label:'Debit or Credit', required: false },
            exp: { error: '', value: '', isdisabled: true, label:'Explanation', required: false },
            amount: { error: '', value: '', isdisabled: true, label:'Amount', required: false },
            particulars: { error: '', value: '', label:'Particulars', required: true },
        };
        var detailRows = [];
        var newRow = { account: { value: '', isdisabled: false }, dr: { value: 0, isdisabled: false }, cr: { value: 0, isdisabled: false } };

        this.setState({ masterRow: masterRow, detailRows: detailRows, newRow: newRow, isDataLoading: false, disableSave: true });
    }
    SaveEntry = (e) => {
        var values = [];
        var masterRow = Object.entries(this.state.masterRow);
        masterRow.forEach((v) => {
            values.push({ name: v[0], value: v[1].value, required: v[1].required });
        });
        var detailRows = JSON.stringify(this.state.detailRows);        
        values.push({ name: 'details', value: detailRows, required: false });
        Global.saveData(e,this, values, this.initializeForm);
    }
    addRow = () => {
        var rows = this.state.detailRows;
        rows.push({ 'account': this.state.newRow.account.value, 'dr': this.state.newRow.dr.value, 'cr': this.state.newRow.cr.value });
        var totalDr = 0;
        var totalCr = 0;
        rows.forEach((v) => {
            totalDr += parseFloat(v.dr);
            totalCr += parseFloat(v.cr);
        });
        var disableSave = true;
        if (totalDr > 0 && totalDr === totalCr) {
            disableSave = false;
        }
        this.setState({ disableSave: disableSave, detailRows: rows, newRow: { account: { value: '', isdisabled: false }, dr: { value: 0, isdisabled: false }, cr: { value: 0, isdisabled: false } } });
    }
    DeleteRow = (index) => {
        var rows = this.state.detailRows;
        rows.splice(index);
        var totalDr = 0;
        var totalCr = 0;
        rows.forEach((v) => {
            totalDr += parseFloat(v.dr);
            totalCr += parseFloat(v.cr);
        });
        var disableSave = true;
        if (totalDr > 0 && totalDr === totalCr) {
            disableSave = false;
        }

        this.setState({ rows, disableSave: disableSave });
    }
    openSnackBar = this.props.GeneralJournal.openSnackBar;
    actionButtons = () => {
        return (
            this.state.disableSave === false ?
                <Box style={inlineStyles.toolbarActionBox}>
                    <Button onClick={this.SaveEntry} style={inlineStyles.buttonOrange} variant='contained' size="small" >
                        <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save Entries
                    </Button>
                </Box>
                : 
                <Box style={inlineStyles.toolbarActionBox}>
                    <Button variant='contained' size="small" disabled>
                        <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save Entries
                    </Button>
                </Box>
        )
    }

    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, false); /* if second parameter is true, the record to edit will be loaded (getOneRow()); else list will be loaded */
        }
    }
    render() {
        if (this.state.branchId!==this.props.parentComponent.state.branchId) {
            this.setState({branchId:this.props.parentComponent.state.branchId});
        }
        if (!this.state.branchId || this.state.branchId<1 ) {
            var notification = 'Please select a branch first';
        } else {
            var notification = this.state.notification;
        }
        
        var headRows = [];
        var masterRow = this.state.masterRow;
        var detailRows = this.state.detailRows;
        var accountSelections = [];
        var dcmcCodeSelections = [];
        if (typeof this.state.schema[0] !== 'undefined' && this.state.schema.length > 1) {
            accountSelections = this.state.schema[0].items;
            dcmcCodeSelections = this.state.schema[1].items;
        }

        headRows.push({ id: 'accountcode', label: 'Account Code', numeric: false, showInList: true });
        headRows.push({ id: 'debit', label: 'Debit', numeric: false, showInList: true });
        headRows.push({ id: 'credit', label: 'Credit', numeric: false, showInList: true });
        headRows.push({ id: 'action', label: '', numeric: false, showInList: true });

        var errors = this.state.errors;
        errors.forEach((v,k)=>{
            if (masterRow.hasOwnProperty(v.key)){
                masterRow[v.key].error = v.error;
            }
        })
        return (
            <Fragment>
                <EnhancedTableToolbar
                    numSelected={0}
                    toolbarTitle=''
                    actionButtons={this.actionButtons}
                    t={this}
                    Global={Global}
                />
                <Paper style={inlineStyles.paper}>
                    {
                        notification &&
                        <div style={{ 'padding': 10, 'color': 'red', 'textAlign': 'center' }}>
                            {notification}
                        </div>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }

                    {!this.state.isDataLoading &&
                        <Fragment>
                            <form autoComplete="off" style={inlineStyles.editform}>
                            <Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            error={masterRow.trandate.error ? true : false}
                                            variant="outlined"
                                            margin="dense"
                                            name="trandate"
                                            type="date"
                                            label={masterRow.trandate.label + (masterRow.trandate.error ? ' is Required' : '')}
                                            required={true}
                                            label={masterRow.trandate.label ? masterRow.trandate.error : "Date"}
                                            value={masterRow.trandate.value}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={this.handleTextfieldChange("trandate")}
                                            disabled={this.state.isDataLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            error={masterRow.jvno.error ? true : false}
                                            variant="outlined"
                                            margin="dense"
                                            name="jvno"
                                            required={true}
                                            label={masterRow.jvno.label  + (masterRow.jvno.error ? ' is Required' : '')}
                                            value={masterRow.jvno.value}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={this.handleTextfieldChange("jvno")}
                                            disabled={this.state.isDataLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            select
                                            error={masterRow.dcmcCode.error ? true : false}
                                            variant="outlined"
                                            margin="dense"
                                            name="dcmcCode"
                                            label={masterRow.dcmcCode.label  + (masterRow.dcmcCode.error ? ' is Required' : '')}
                                            value={masterRow.dcmcCode.value}
                                            onChange={this.handleTextfieldChange("dcmcCode")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={this.state.isDataLoading}
                                        >
                                            {
                                                dcmcCodeSelections.length > 0 && dcmcCodeSelections.map((itemrow, itemindex) => {
                                                    return (
                                                        <MenuItem key={itemindex} value={itemrow.key}>{itemrow.value}</MenuItem>
                                                    )
                                                })
                                            }

                                        </TextField>
                                    </Grid>


                                    <Grid item xs={12} md={12}>
                                        <Grid container spacing={2} style={{ backgroundColor: '#f7f5ea', border: '1px solid #d8d582' }}>
                                            <Grid item xs={12} md={12}>
                                                <em style={{ color: '#ca751d', fontSize: '90%' }}>*Entries that will reflect in Client PN ledger (returned checks, adjustments, etc.)
                                                </em>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControlLabel align="center"
                                                    control={
                                                        <Checkbox
                                                            onClick={this.handleChecked('adjust')}
                                                            checked={masterRow.adjust.value}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Adjust Client PN Ledger?"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    error={masterRow.pn.error ? true : false}
                                                    variant="outlined"
                                                    margin="dense"
                                                    name="pn"
                                                    required={true}
                                                    label={masterRow.pn.label  + (masterRow.pn.error ? ' is Required' : '')}
                                                    value={masterRow.pn.value}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={this.handleTextfieldChange("pn")}
                                                    disabled={this.state.isDataLoading || masterRow.pn.isdisabled}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <RadioGroup row aria-label="drcr" name="drcr" value={masterRow.drcr.value} onChange={this.handleToggle('drcr')}>
                                                    <FormControlLabel value="dr" disabled={this.state.isDataLoading || masterRow.drcr.isdisabled} control={<Radio color="primary" size="small" />} label="Debit" />
                                                    <FormControlLabel value="cr" disabled={this.state.isDataLoading || masterRow.drcr.isdisabled} control={<Radio color="primary" size="small" />} label="Credit" />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <TextField
                                                    error={masterRow.exp.error ? true : false}
                                                    variant="outlined"
                                                    margin="dense"
                                                    name="exp"
                                                    required={true}
                                                    label={masterRow.exp.label  + (masterRow.exp.error ? ' is Required' : '')}
                                                    value={masterRow.exp.value}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={this.handleTextfieldChange("exp")}
                                                    disabled={this.state.isDataLoading || masterRow.exp.isdisabled}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    error={masterRow.amount.error ? true : false}
                                                    variant="outlined"
                                                    margin="dense"
                                                    name="amount"
                                                    required={true}
                                                    label={masterRow.amount.label  + (masterRow.amount.error ? ' is Required' : '')}
                                                    value={masterRow.amount.value}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={this.handleTextfieldChange("amount")}
                                                    disabled={this.state.isDataLoading || masterRow.amount.isdisabled}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            error={masterRow.particulars.error ? true : false}
                                            variant="outlined"
                                            margin="dense"
                                            name="particulars"
                                            required={true}
                                            label={masterRow.particulars.label  + (masterRow.particulars.error ? ' is Required' : '')}
                                            value={masterRow.particulars.value}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={this.handleTextfieldChange("particulars")}
                                            disabled={this.state.isDataLoading}
                                        />
                                    </Grid>
                                </Grid>

                            </Fragment>

                            <div style={inlineStyles.tableWrapper}>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size='small'
                                >
                                    <EnhancedTableHead
                                        numSelected={this.state.selected ? this.state.selected.length : 0}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        headRows={headRows}
                                        noHashColumn={true}
                                    />
                                    <TableBody>
                                        {detailRows.map((row, index) => {
                                            var accountName = accountSelections.find(colItems => colItems.key == row.account);
                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                >
                                                    <TableCell key={index}>
                                                        {accountName.value}
                                                    </TableCell>
                                                    <TableCell align={'right'}>
                                                        {numberFormat(row.dr, 2)}
                                                    </TableCell>
                                                    <TableCell align={'right'}>
                                                        {numberFormat(row.cr, 2)}
                                                    </TableCell>

                                                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                                        <IconButton style={inlineStyles.iconButton} onClick={() => this.DeleteRow(index)} aria-label="Delete row">
                                                            <Icon className={'fas fa-trash-alt fa-small'} color="error" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        }
                                        <TableRow>
                                            <TableCell>
                                                <TextField
                                                    select
                                                    variant="outlined"
                                                    margin="dense"
                                                    name="account"
                                                    label={'Account'}
                                                    value={this.state.newRow.account.value}
                                                    onChange={this.handleAddRowChange("account")}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={this.state.isDataLoading}
                                                >
                                                    {
                                                        accountSelections.length > 0 && accountSelections.map((itemrow, itemindex) => {
                                                            return (
                                                                <MenuItem key={itemindex} value={itemrow.key}>{itemrow.value}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </TableCell>
                                            <TableCell align={'right'}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    name="dr"
                                                    required={true}
                                                    label={"Debit"}
                                                    value={this.state.newRow.dr.value}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={this.handleAddRowChange("dr")}
                                                    disabled={this.state.isDataLoading || this.state.newRow.dr.isdisabled}
                                                />
                                            </TableCell>
                                            <TableCell align={'right'}>
                                                <TextField
                                                    error={masterRow.amount.error ? true : false}
                                                    variant="outlined"
                                                    margin="dense"
                                                    name="cr"
                                                    required={true}
                                                    label={"Credit"}
                                                    value={this.state.newRow.cr.value}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={this.handleAddRowChange("cr")}
                                                    disabled={this.state.isDataLoading || this.state.newRow.cr.isdisabled}
                                                />
                                            </TableCell>

                                            <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                                <Button onClick={this.addRow} style={inlineStyles.buttonBlue} variant='contained' size="small">
                                                    Add
                                                </Button>
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </div>
                            </form>
                        </Fragment>
                    }

                </Paper>
                
            </Fragment>
        );
    }
}