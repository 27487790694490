import React, { Fragment, Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { numberFormat } from '../../utils/common_utils';
import 'date-fns';
import '../../styles.css';
import { EnhancedTableToolbar, EnhancedTableHead } from '../../utils/common_page_with_table';
import { Global, inlineStyles } from '../../utils/global';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SnackerBar from '../../utils/SnackerBar';
import Button from '@material-ui/core/Button';
import { MenuItem, Link,Grid } from '@material-ui/core';
import { Column, Row } from 'simple-flexbox';
import 'date-fns';

const reportTitle = "CMC Adjustments Report";
const url = 'cash/adjustments.php';
const EntityName = 'Adjustment';

export default class Adjustments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branchId: 0,
            count: 1,
            deleteDialogOpen: false,
            editDialogOpen: false,
            warningDialogOpen: false,
            dense: false,
            id: 0,
            isLoading: true,
            isDataLoading: false,
            isDialogDataLoading: false,
            notification: '',
            objName: '',
            order: 'desc',
            orderBy: 'dateTransaction',
            page: 0,
            pageTotal: 0,
            refreshAfterSave: false,
            rows: [],
            rowsPerPage: 100,
            searchFor: '',
            selected: [],
            selectedDate: '',
            selectedDate2: '',
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            showClearSearchBtn: false,
            url: props.parentComponent.state.API_HOST + url,
            schema: [],
        };
    }
    addNew = () => {
        var values = { ...this.state.schema };
        values.id = 0;
        this.setState({ values });
        if (this.props.parentComponent.state.branchId===0 || this.props.parentComponent.state.branchId==='0') {
            this.setWarningDialogOpen(true);
        } else {
            this.setEditDialogOpen(true);
        }
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
        return;
    };
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    handleRequestSort = (event, property) => {
        var isDesc = this.state.order === 'desc';
        var order = isDesc || this.state.orderBy !== property ? 'asc' : 'desc';
        var values = {
            order: order,
            orderBy: property,
        };
        this.setComponentState('order', order);
        this.setComponentState('orderBy', property);
        Global.loadList(this, values);
    }
    changeDate = (e) => {
        var v = e.target.value;
        this.setState({ selectedDate: v });
    }
    changeDate2 = (e) => {
        var v = e.target.value;
        this.setState({ selectedDate2: v });
    }
    setSearch = () => {
        if (this.state.selectedDate) {
            Global.getDataRows(this, this.state.url, 'rows', '', [{ name: 'selectedDate', value: this.state.selectedDate }, { name: 'selectedDate2', value: this.state.selectedDate2 }]);
        }
    }
    previewCMC = (transID) => {
        Global.showPostPage(this, this.state.url, 'printByBranch', [{ name: 'reportdate', value: this.state.selectedDate }, { name: 'transID', value: transID }]);
    }
    printCMC = () => {
        Global.showPostPage(this, this.state.url, 'printAll', [{ name: 'reportdate', value: this.state.selectedDate }]);
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            var newSelecteds = this.state.rows.map(n => n.name);
            this.setComponentState('selected', newSelecteds);
            return;
        }
        this.setComponentState('selected', []);
    }

    handleChangePage = (event, newPage) => {
        this.setComponentState('page', newPage);
        Global.loadList(this, { page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setComponentState('rowsPerPage', event.target.value);
        Global.loadList(this, { rowsPerPage: event.target.value });
    }

    handleChangeDense = (event) => {
        this.setComponentState('dense', event.target.checked);
    }


    actionButtons = () => {
        return (
            <Column flexGrow={1}>
                <Row vertical='center' horizontal='right'>
                    <Column flexGrow={1} horizontal='center'>
                        From:
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        <TextField
                            error={false}
                            variant="outlined"
                            margin="dense"
                            type="date"
                            required={true}
                            onChange={this.changeDate}
                            disabled={this.state.isDataLoading}
                        />
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        To:
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        <TextField
                            error={false}
                            variant="outlined"
                            margin="dense"
                            type="date"
                            required={true}
                            onChange={this.changeDate2}
                            width={100}
                            disabled={this.state.isDataLoading}
                        />
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        <Button onClick={this.setSearch} style={{ marginLeft: 5, padding: "0 5px" }} color="primary" variant='contained' size="small">
                            Search
                            </Button>
                    </Column>
                    <Column flexGrow={1} horizontal='center'>
                        <Button style={{ marginLeft: 5, padding: "0 5px", backgroundColor: "#ffc107" }} variant='contained' size="small" onClick={this.addNew}>
                            Add Adjustment
                    </Button>
                    </Column>
                </Row>
            </Column>
        )
    }
    setEditDialogOpen = (v) => {
        this.setState({ editDialogOpen: v });        
    }
    setWarningDialogOpen = (v) => {
        this.setState({ warningDialogOpen: v });        
    }
    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            var postData = [];
            postData.push({name: 'branchId', value: this.props.parentComponent.state.branchId});
            Global.getSchema(this, false, null, postData);
        }
    }
    componentDidUpdate() {
        if (this.state.branchId!==this.props.parentComponent.state.branchId || this.state.refreshAfterSave) {
            this.setState({ branchId: this.props.parentComponent.state.branchId, refreshAfterSave: false });    
            Global.loadList(this,{ branchId: this.props.parentComponent.state.branchId });            
        }
    }
    render() {
        var headRows = [];
        var formValues = this.state.schema;
        formValues.forEach((row) => {
            headRows.push({ id: row.name, label: row.label, numeric: row.numeric, showInList: row.showInList });
        });
        var menuItems;        
        
        headRows.push({ id: 'action', label: 'Actions', numeric: false, showInList: true });

        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={this.state.selected ? this.state.selected.length : 0}
                                toolbarTitle={reportTitle}
                                actionButtons={this.actionButtons}
                                t={this}
                                Global={Global}
                            />
                            <div style={inlineStyles.tableWrapper}>
                                <Table
                                    style={inlineStyles.table}
                                    aria-labelledby="tableTitle"
                                    size={this.state.dense ? 'medium' : 'small'}
                                >
                                    <EnhancedTableHead
                                        numSelected={this.state.selected ? this.state.selected.length : 0}
                                        order={this.state.order}
                                        orderBy={this.state.orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        headRows={headRows}
                                    />
                                    <TableBody>
                                        {this.state.rows && this.state.rows.length > 0 && this.state.rows.map((row, index) => {
                                            const labelId = `table-row-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                >
                                                    <TableCell className="listRowNum" id={labelId} scope="row" padding="none" align="center">
                                                        {index + 1 + (this.state.page * this.state.rowsPerPage)}
                                                    </TableCell>
                                                    {formValues.filter(col => col.showInList).map((col, colindex) => {
                                                        const colval = colindex
                                                            ? row[col.name]
                                                            : (<Link to="#" onClick={() => this.previewCMC(row.transID)} >{row[col.name]}</Link>);
                                                        return (
                                                            <TableCell key={colindex} align={col.numeric ? 'right' : (col.align ? col.align : 'left')}>
                                                                {col.numeric ? numberFormat(colval, 2) : colval}
                                                            </TableCell>
                                                        );
                                                    })
                                                    }

                                                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                                                        <Button color="primary" variant="outlined" size="small" onClick={() => this.previewCMC(row.transID)}>
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        }
                                        {this.state.rows && !this.state.rows.length &&
                                            <TableRow><TableCell align="center" colSpan={8}>{"No result"}</TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            {!this.state.isDataLoading && this.state.pageTotal > 1 &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={this.state.count}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            }
                        </Fragment>
                    }
                </Paper>
                <FormControlLabel
                    control={<Switch checked={this.state.dense} onChange={this.handleChangeDense} />}
                    label="Show bigger cells"
                />
                <Dialog
                    open={this.state.editDialogOpen}
                    aria-labelledby="alert-dialog2-title"
                    aria-describedby="alert-dialog2-description"
                >
                    <DialogTitle id="alert-dialog2-title">{this.state.id ? "Edit " + EntityName : "Add " + EntityName}</DialogTitle>
                    <DialogContent dividers>
                        <Fragment>
                            {
                                (this.props.parentComponent.state.branchId===0 || this.props.parentComponent.state.branchId==='0') &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 5 }}>Please Select a Branch</Typography>
                            }
                            {
                                this.state.notification &&
                                <Typography paragraph color="error" align="center" style={{ 'padding': 5 }}>
                                    {this.state.notification}
                                </Typography>
                            }
                            {
                                this.state.isDialogDataLoading &&
                                <div style={{ padding: 20, minWidth: 350, textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                            }
                            {!this.state.isDialogDataLoading &&
                                <Fragment>
                                    <Grid container spacing={1}>
                                        {formValues.filter((row) => row.edit && row.type !== 'select').map((row, index) => {
                                            return (
                                                <Grid item xs={12} md={row.gridsize ? row.gridsize : 4} key={index}>
                                                    <TextField
                                                        error={row.error ? true : false}
                                                        variant="outlined"
                                                        margin="dense"
                                                        name={row.name}
                                                        type={row.type}
                                                        required={row.required}
                                                        label={row.error ? row.error : row.label}
                                                        value={row.value}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleTextfieldChange(row.name)}
                                                        disabled={this.state.isDataLoading || row.noEdit}
                                                    />
                                                </Grid>
                                            )
                                        })
                                        }
                                        {formValues.filter(row => row.edit && row.type === 'select').map((row, index) => {
                                            menuItems = row.items;
                                            return (
                                                <Grid item xs={12} md={row.gridsize ? row.gridsize : 4} key={index}>
                                                    <TextField
                                                        select
                                                        error={row.error ? true : false}
                                                        variant="outlined"
                                                        margin="dense"
                                                        name={row.name}
                                                        label={row.error ? row.error : row.label}
                                                        value={row.value ? row.value : ''}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleTextfieldChange(row.name)}
                                                        disabled={this.state.isDataLoading}
                                                    >
                                                        <MenuItem value={0}>{row.label}</MenuItem>
                                                        {row.label === 'Branch' && this.props.parentComponent.state.branches.length > 0 && this.props.parentComponent.state.branches.map((itemrow, itemindex) => {
                                                            return (
                                                                itemrow.active &&
                                                                <MenuItem key={itemindex} value={itemrow.key}>{itemrow.value}</MenuItem>
                                                            )
                                                        })
                                                        }
                                                        {
                                                            row.label !== 'Branch' && menuItems.length > 0 && menuItems.map((itemrow, itemindex) => {
                                                                return (
                                                                    <MenuItem key={itemindex} value={itemrow.id}>{itemrow.value}</MenuItem>
                                                                )
                                                            })
                                                        }

                                                    </TextField>
                                                </Grid>
                                            )
                                        })
                                        }
                                    </Grid>
                                </Fragment>
                            }

                        </Fragment>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={Global.handleSaveForm(this)} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
                            Save
                    </Button>
                        <Button onClick={() => Global.handleDialogCancel(this)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                            Cancel
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.warningDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent dividers>
                        <div style={{ padding: 15 }}>
                            Please select a branch first
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setWarningDialogOpen(false)} variant='contained' color="primary" size="small" style={inlineStyles.button}>
                            Close
                    </Button>
                        
                    </DialogActions>
                </Dialog>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}
